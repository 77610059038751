/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getItems = /* GraphQL */ `
  query GetItems($eoi: String) {
    getItems(eoi: $eoi) {
      bucket
      region
      key
    }
  }
`;
export const getAuthors = /* GraphQL */ `
  query GetAuthors {
    getAuthors {
      statusCode
      body
    }
  }
`;
export const removeAuthor = /* GraphQL */ `
  query RemoveAuthor($author: String, $integration: String) {
    removeAuthor(author: $author, integration: $integration) {
      statusCode
      body
    }
  }
`;
export const saveAuthor = /* GraphQL */ `
  query SaveAuthor($author: String, $integration: String) {
    saveAuthor(author: $author, integration: $integration) {
      statusCode
      body
    }
  }
`;
export const voteAuthor = /* GraphQL */ `
  query VoteAuthor($author: String, $integration: String, $vote: Int) {
    voteAuthor(author: $author, integration: $integration, vote: $vote) {
      statusCode
      body
    }
  }
`;
export const getBotConfig = /* GraphQL */ `
  query GetBotConfig {
    getBotConfig {
      statusCode
      body
    }
  }
`;
export const saveBotConfig = /* GraphQL */ `
  query SaveBotConfig(
    $model_version: Int
    $direction_forecast: String
    $period: Int
    $tp_rate: Float
    $sl_rate: Float
    $leverage: Int
    $trade_amt: Float
    $auto: Boolean
    $use_simulator: Boolean
  ) {
    saveBotConfig(
      model_version: $model_version
      direction_forecast: $direction_forecast
      period: $period
      tp_rate: $tp_rate
      sl_rate: $sl_rate
      leverage: $leverage
      trade_amt: $trade_amt
      auto: $auto
      use_simulator: $use_simulator
    ) {
      statusCode
      body
    }
  }
`;
export const closePositions = /* GraphQL */ `
  query ClosePositions {
    closePositions {
      statusCode
      body
    }
  }
`;
export const getBotStats = /* GraphQL */ `
  query GetBotStats {
    getBotStats {
      statusCode
      body
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        message
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      email
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        message
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
