import React, {useEffect, useState, useRef} from "react";
import Amplify, {Auth, Storage} from "aws-amplify";
import _ from 'lodash';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import Load from "./loader";
import moment from "moment";
import {Table, Pagination, Icon, Dropdown, Button} from 'semantic-ui-react'

import awsconfig from '../aws-exports'
import {CSVLink} from 'react-csv'


Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const PredictionTable = ({baseData}) => {
    const [pagedData, setPagedData] = useState([])
    const [data, setData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState(0)
    const [sortColumn, setSortColumn] = useState('profit_rate')
    const [direction, setDirection] = useState('descending')
    const [isSending, setIsSending] = useState(false)
    const [labels, setLabels] = useState([])
    const [label, setLabel] = useState(null)
    const keepAttrs = [
        "label",
        "model_version",
        "direction",
        "probability_rate_from",
        "period",
        "tp_rate",
        "sl_rate",
        "prediction_count",
        "closed_count",
        "open_count",
        "extended_count",
        "max_open_amt",
        "avg_open_amt",
        "profit",
        "profit_rate",
        "simulation_days",
        "simulation_hours",
        "simulation_start_date",
        "simulation_end_date"]
    const csvLink = useRef()

    const header = [
        {
            "name": "Label",
            "width": 1,
            "id": "label"
        },
        {
            "name": "Model",
            "width": 1,
            "id": "model_version"
        },
        {
            "name": "Direction",
            "width": 2,
            "id": "direction"
        },
        {
            "name": "Prob rate from",
            "width": 2,
            "id": "probability_rate_from"
        },
        {
            "name": "Period",
            "width": 2,
            "id": "period"
        },
        {
            "name": "TP %",
            "width": 2,
            "id": "tp_rate"
        },
        {
            "name": "SL %",
            "width": 2,
            "id": "sl_rate"
        },
        {
            "name": "Pred Count",
            "width": 2,
            "id": "prediction_count"
        },
        {
            "name": "Close Count",
            "width": 2,
            "id": "closed_count"
        },
        {
            "name": "Open Count",
            "width": 2,
            "id": "open_count"
        },
        {
            "name": "Ext Count",
            "width": 2,
            "id": "extended_count"
        },
        {
            "name": "Max open amt",
            "width": 3,
            "id": "max_open_amt"
        },
        {
            "name": "Avg open amt",
            "width": 3,
            "id": "avg_open_amt"
        },
        {
            "name": "Profit",
            "width": 3,
            "id": "profit"
        },
        {
            "name": "Profit %",
            "width": 3,
            "id": "profit_rate"
        },
        {
            "name": "Days",
            "width": 3,
            "id": "simulation_days"
        },
        {
            "name": "Hours",
            "width": 3,
            "id": "simulation_hours"
        },
        {
            "name": "Start date",
            "width": 4,
            "id": "simulation_start_date"
        },
        {
            "name": "End date",
            "width": 4,
            "id": "simulation_end_date"
        }
    ]

    const options = {
        // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: true,
        enableOptimizations: true,
        fontFamily: "sans-serif",
        fontSizes: [8, 64],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 1,
        rotations: 0,
        rotationAngles: [90, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 500
    };

    const handlePageClick = (e, {activePage}) => setActivePage(activePage)

    useEffect(() => {
        if (_.isEmpty(pagedData)) return

        setData(pagedData[activePage - 1])

    }, [activePage])

    useEffect(() => {
        if (_.isEmpty(label)) return

        // filter simulations
        const filtered_data = _.filter(baseData, {'label': label})

        const mapped_data = _.map(filtered_data, function (item) {
            item = _.pick(item, keepAttrs)
            return item
        })

        // Order data
        let dir = (direction === 'descending') ? 'desc' : 'asc'
        let orderedData = _.orderBy(mapped_data, [sortColumn], [dir])

        let itmes = _.chunk(orderedData, 100)
        let pages = itmes.length

        setPagedData(itmes)
        setPages(pages)
        setIsSending(false)
        setData(itmes[0])

    }, [label])

    // start a query
    useEffect(() => {
        if (isSending || _.isEmpty(baseData)) return

        setIsSending(true)

        // Select latest simulation
        const last_simu_obj = _.maxBy(baseData, 'simulation_end_date');
        const last_simulation = _.filter(baseData, {'simulation_end_date': last_simu_obj.simulation_end_date})

        //  select list of simulation labels
        const uniq_labels = _.uniqBy(baseData, 'label')
        let labels = []
        _.map(uniq_labels, function (item) {
            let value = null
            if (moment(item.label, 'YYYY-MM-DD HH:mm:ss', true).isValid()){
                value = moment(moment.utc(item.label).toDate()).format('YYYY-MM-DD HH:mm:ss')
            }else{
                value = item.label
            }
            labels.push({
                "key": item.label,
                "text": value,
                "value": item.label
            })
        })

        let sorted_labels = _.orderBy(labels, ['key'], ['desc'])

        const filtered_data = _.map(last_simulation, function (item) {
            item = _.pick(item, keepAttrs)
            return item
        })

        // Order data

        let dir = (direction === 'descending') ? 'desc' : 'asc'
        let orderedData = _.orderBy(filtered_data, [sortColumn], [dir])

        let itmes = _.chunk(orderedData, 100)
        let pages = itmes.length

        setPagedData(itmes)
        setLabels(sorted_labels)
        setPages(pages)
        setIsSending(false)
        setData(itmes[0])

    }, [baseData, isSending])

    const handleDropdownChange = (evt, option) => {
        evt.preventDefault();
        const {value} = option;
        setLabel(value);
    }

    const sortTable = (column) => {
        let sortDirection = 'ascending'
        let orderedData = []

        const filtered_data = (_.isNull(label))? baseData : _.filter(baseData, {'label': label})

        let dir = (direction === 'descending') ? 'desc' : 'asc'

        if (column === sortColumn) {

            dir = direction === 'ascending' ? 'desc' : 'asc'
            sortDirection = direction === 'ascending' ? 'descending' : 'ascending'
        }
        orderedData = _.orderBy(filtered_data, [column], [dir])

        let newPagedData = _.chunk(orderedData, 100)
        // setPages(newPagedData.length)
        setPagedData(newPagedData)
        setSortColumn(column)
        setDirection(sortDirection)
        setData(newPagedData[0])
    }

    return (
        <div>
            <div className="ui divider"></div>
            {!_.isEmpty(data) ? (
                <div>
                    <Dropdown
                        search
                        selection
                        basic
                        options={labels}
                        placeholder={'Select a Simulation'}
                        id={'label'}
                        onChange={handleDropdownChange}
                        value={label}
                        closeOnBlur={true}
                    />
                    <CSVLink
                        data={data}
                        filename='simulation.csv'
                        ref={csvLink}
                        target='_blank'
                    >
                        <Button
                            loading={_.isEmpty(data)}
                            basic
                            size='medium'
                            animated='fade'
                            // onClick={(e) => onVote(e, item, 1)}
                            color="blue"
                        >
                            <Button.Content hidden>
                                <Icon name='cloud download'/>
                            </Button.Content>
                            <Button.Content visible>
                                Download
                            </Button.Content>
                        </Button>
                    </CSVLink>
                    <div className={'table-container'}>
                        {!_.isEmpty(data) && (
                            <Table sortable celled padded={true} size={'small'} columns={header.length}
                                   singleLine={true} className={'prediction-table'}>
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            header.map((column) => (
                                                <Table.HeaderCell
                                                    key={column.name}
                                                    width={column.width}
                                                    sorted={column.id === sortColumn ? direction : null}
                                                    onClick={() => sortTable(column.id)}
                                                >
                                                    {column.name}
                                                </Table.HeaderCell>
                                            ))
                                        }
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.map((item, index) => (
                                        <Table.Row key={'index-' + index}>
                                            <Table.Cell>{moment(item.label, 'YYYY-MM-DD HH:mm:ss', true).isValid()?moment(moment.utc(item.label).toDate()).format('YYYY-MM-DD HH:mm:ss'):item.label}</Table.Cell>
                                            <Table.Cell>{item.model_version}</Table.Cell>
                                            <Table.Cell>{item.direction}</Table.Cell>
                                            <Table.Cell>{item.probability_rate_from}</Table.Cell>
                                            <Table.Cell>{item.period}</Table.Cell>
                                            <Table.Cell>{item.tp_rate}</Table.Cell>
                                            <Table.Cell>{item.sl_rate}</Table.Cell>
                                            <Table.Cell>{item.prediction_count}</Table.Cell>
                                            <Table.Cell>{item.closed_count}</Table.Cell>
                                            <Table.Cell>{item.open_count}</Table.Cell>
                                            <Table.Cell>{item.extended_count}</Table.Cell>
                                            <Table.Cell>{_.round(item.max_open_amt, 2)}</Table.Cell>
                                            <Table.Cell>{_.round(item.avg_open_amt, 2)}</Table.Cell>
                                            <Table.Cell>{_.round(item.profit, 2)}</Table.Cell>
                                            <Table.Cell>{_.round(item.profit_rate, 2)}</Table.Cell>
                                            <Table.Cell>{item.simulation_days}</Table.Cell>
                                            <Table.Cell>{item.simulation_hours}</Table.Cell>
                                            <Table.Cell>{moment(moment.utc(item.simulation_start_date).toDate()).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                            <Table.Cell>{moment(moment.utc(item.simulation_end_date).toDate()).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={header.length}>
                                            <Pagination
                                                activePage={activePage}
                                                onPageChange={handlePageClick}
                                                totalPages={pages}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        )}
                    </div>
                </div>
            ) : ('')}
            {(isSending) && (
                <Load text={"Loading prediction data"}/>
            )}
        </div>
    )
}

export default PredictionTable