import React, {useEffect, useState} from "react";
import {Form, Message, FormInput, Popup, Icon, Dropdown, Header, Checkbox} from 'semantic-ui-react'
import _ from 'lodash';
import {API, graphqlOperation} from "aws-amplify";
import {saveBotConfig} from "../graphql/queries";

const BotConfig = ({config}) => {
    const [baseConfig, setConfig] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [inputs, setInputs] = useState(config)
    const [disabled, setDisable] = useState(true)

    const authorFormFields = [
        {
            as: FormInput,
            label: 'Model versions',
            key: 'model_version',
            id: 'model_version',
            placeholder: 'model_version',
            required: true,
            type: 'Dropdown',
            error: false,
            hint: 'Select the model',
            simulator: false,
            options: [
                {key: '2', text: 'v2', value: 2},
                {key: '3', text: 'v3', value: 3},
                {key: '4', text: 'v4', value: 4}
            ]
        },
        {
            as: FormInput,
            label: 'Period',
            key: 'period',
            id: 'period',
            placeholder: 'period',
            required: true,
            type: 'Dropdown',
            error: false,
            hint: 'Select the period',
            options: [
                {key: '24', text: '24 hrs', value: 24}
            ]
        },
        {
            as: FormInput,
            label: 'Take Profit',
            key: 'tp_rate',
            id: 'tp_rate',
            placeholder: 'tp_rate',
            required: true,
            type: 'Number',
            error: false,
            hint: 'Enter the TP rate',
            simulator: false,
            min: 0.10,
            step:".01"
        },
        {
            as: FormInput,
            label: 'Stop Loss',
            key: 'sl_rate',
            id: 'sl_rate',
            placeholder: 'sl_rate',
            required: true,
            type: 'Number',
            error: false,
            hint: 'Enter the SL rate',
            simulator: false,
            min: 0.10,
            step:".01"
        },
        {
            as: FormInput,
            label: 'Leverage',
            key: 'leverage',
            id: 'leverage',
            placeholder: 'leverage',
            required: true,
            type: 'Number',
            error: false,
            hint: 'Enter the leverage',
            min: 0
        },
        {
            as: FormInput,
            label: 'Trade amt',
            key: 'trade_amt',
            id: 'trade_amt',
            placeholder: 'trade_amt',
            required: true,
            type: 'Number',
            error: false,
            hint: 'Enter the minimum trade amount',
            min: 1,
            step:".01"
        },
        {
            as: FormInput,
            label: 'Automatic trading',
            key: 'auto',
            id: 'auto',
            placeholder: 'auto',
            required: true,
            type: 'checkbox',
            error: false,
            hint: 'automatic trading every hour',
        },
        {
            as: FormInput,
            label: 'Use simulator',
            key: 'use_simulator',
            id: 'use_simulator',
            placeholder: 'use_simulator',
            required: true,
            type: 'checkbox',
            error: false,
            hint: 'Rely on last simulation for TP and SL',
        }
    ];

    const onSave = (e, author) => {
        e.preventDefault()

        if (loading) return

        setLoading(true)

        const go = async (author) => {

            try {
                const result = await API.graphql(
                    graphqlOperation(saveBotConfig, inputs)
                )

                let body = JSON.parse(result.data.saveBotConfig.body)
                console.log(`Saved author : ${result.data.saveBotConfig.statusCode} - ${body.message}`)
                if (result.data.saveBotConfig.statusCode === "200") {
                    let message = {
                        header: 'Success',
                        content: body.message
                    }
                    setMessage(message)
                    setSuccess(true)

                } else {
                    let message = {
                        header: 'Warning',
                        content: body.error
                    }
                    setMessage(message)
                    setError(true)
                }

                setLoading(false)
            } catch (error) {
                console.log('querry failed ->', error)
                message.header = 'Error'
                message.content = error
                setMessage(message)
                setError(true)
                setLoading(true)
            }
        }
        go(baseConfig)
    }

    const onChangeCheck = (e, id) => {
        e.preventDefault()

        if (!inputs) return

        let form_inputs = inputs

        form_inputs = {
            ...form_inputs,
            [id]: !form_inputs[id]
        }
        setInputs(form_inputs);

    }

    const onChange = (e, d) => {
        e.preventDefault()

        if (!inputs) return

        let {id, value} = e.target;

        let form_inputs = inputs

        let field = _.find(authorFormFields, {"id": id})

        value = (field.type === 'Number' && _.isUndefined(field.step))? parseInt(value) : value
        value = (field.type === 'Number' && !_.isUndefined(field.step))? parseFloat(value) : value
        form_inputs = {
            ...form_inputs,
            [id]: value
        }
        setInputs(form_inputs);

    }

    const handleDropdownChange = (evt, option, key) => {
        evt.preventDefault();
        const {value, id} = option;
        let form_inputs = inputs;

        form_inputs = {
            ...form_inputs,
            [id]: value
        }
        setInputs(form_inputs);
    }

    const getInvalidFields = React.useCallback((inputs) => {

        let invalidsFields = [];
        let value = null
        _.map(authorFormFields, function (field) {
            value = (field.type === 'Number') ? parseFloat(inputs[field.id]) : inputs[field.id]
            if (field.required && (_.isString(value) && !value) || (_.isNumber(value) && value < 0.1) || _.isNaN(value)) {
                field.error = true;
                invalidsFields.push(`${field.label} is required`);
            } else {
                field.error = false;
            }
        })

        return invalidsFields;
    }, [])

    // fetch Word Cloud data
    useEffect(() => {
        if (!inputs) return

        let cleanForm = _.isEmpty(inputs);
        let invalids = cleanForm || getInvalidFields(inputs);

        if (invalids && !_.isEmpty(invalids)) {
            let message = {
                header: 'Validation error',
                list: invalids
            }

            setError(true)
            setDisable(true)
            setMessage(message)
        } else {
            setError(false)
            setDisable(cleanForm ? true : false)
        }

    }, [inputs, getInvalidFields])

    return (
        <div className={'control-panel'}>
            <Header as='h3' textAlign={'center'} dividing>Bot Configuration</Header>
            <Form
                onSubmit={(e) => onSave(e)}
                loading={loading}
                error={error}
                success={success}
            >
                {(error || success) && (
                    <Message
                        success={success}
                        error={error}
                        header={message.header}
                        content={message.content}
                        list={message.list}
                    />
                )}
                {authorFormFields.map(field => {
                    if (!_.isUndefined(field.simulator) && field.simulator !== inputs['use_simulator']){
                        return;
                    }

                    if (field.type === 'Dropdown') {
                        return (
                            <Form.Field required={field.required}>
                                <label>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top left'
                                        hoverable={true}
                                    />)}
                                </label>
                                <Dropdown
                                    button
                                    basic
                                    fluid
                                    selection
                                    options={field.options}
                                    icon='angle down'
                                    placeholder={field.placeholder}
                                    id={field.id}
                                    onChange={handleDropdownChange}
                                    value={_.isUndefined(inputs) || _.isUndefined(inputs[field.id]) ? '' : inputs[field.id]}
                                    closeOnBlur={true}
                                />
                            </Form.Field>
                        )
                    }

                    if (field.type === 'checkbox') {
                        return (
                            <Form.Field required={field.required}>
                                <label>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top left'
                                        hoverable={true}
                                    />)}
                                </label>
                                <Checkbox
                                    toggle
                                    value={_.isUndefined(inputs) || _.isUndefined(inputs[field.id]) ? '' : inputs[field.id]}
                                    checked={_.isUndefined(inputs[field.id]) ? false : inputs[field.id]}
                                    onChange={(e, d) => onChangeCheck(e, field.id)}
                                />
                            </Form.Field>
                        )
                    }

                    return (
                        <Form.Input
                            {...field}
                            label={
                                <label id={field.id}>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top right'
                                        hideOnScroll
                                    />)}
                                </label>
                            }
                            value={_.isUndefined(inputs) || _.isUndefined(inputs[field.key]) ? '' : String(inputs[field.key])}
                            onChange={(e, d) => onChange(e, d)}
                        />
                    )
                })}
                <Form.Button
                    basic fluid
                    type={'submit'}
                    color={'green'}
                    content={'Save'}
                    disabled={disabled}
                />
            </Form>
        </div>
    )
}

export default BotConfig;