import React, {useEffect, useState} from "react";
import Amplify, {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {I18n} from '@aws-amplify/core';
import _ from 'lodash';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import Load from "./loader";

import {strings as mainAppStrings} from './dictionary'
import BotConfig from "./bot-confiig";

import awsconfig from '../aws-exports'
import {closePositions, getBotStats} from "../graphql/queries";
import {Button, Header, Icon, Message, Modal} from "semantic-ui-react";

I18n.putVocabularies(mainAppStrings);

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const BotPage = ({config}) => {

    const [isSending, setIsSending] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [openCancelPositions, setOpenCancelPositions] = useState(false)
    const [closePositionsLoader, setClosePositionsLoader] = useState(false)
    const [message, setMessage] = useState({})
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const onPressAdd = () => {
        // e.preventDefault()
        setOpenConfig(!openConfig)
    }

    const onPressCancelPositions = () => {
        // e.preventDefault()
        setOpenCancelPositions(!openCancelPositions)
    }

    // Close Positions
    const onClosePositions = (e) => {
        e.preventDefault()

        setClosePositionsLoader(true)

        const go = async () => {

            try {
                const result = await API.graphql({
                    query: closePositions,
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                })

                let body = JSON.parse(result.data.closePositions.body)
                console.log(`Close positions : ${result.data.closePositions.statusCode} - ${body.message}`)
                if (result.data.closePositions.statusCode === "200") {

                    let message = {
                        header: 'Success',
                        content: body.message
                    }
                    setMessage(message)
                    setSuccess(true)
                } else {
                    let message = {
                        header: 'Warning',
                        content: body.error
                    }
                    setMessage(message)
                    setError(true)
                }
                setClosePositionsLoader(false)

            } catch (error) {
                setClosePositionsLoader(false)
                console.log('querry failed ->', error)
            }
        }
        go()
    }

    return (
        <div>
            {(!_.isEmpty(config)) ? (
                <div>
                    {/*<Button*/}
                    {/*    className={'top right'}*/}
                    {/*    animated='fade'*/}
                    {/*    color={'green'}*/}
                    {/*    circular*/}
                    {/*    onClick={(e) => onPressAdd()}>*/}
                    {/*    <Button.Content visible><Icon name='setting'/></Button.Content>*/}
                    {/*    <Button.Content hidden>setting</Button.Content>*/}
                    {/*</Button>*/}
                    {/*/!*todo add cards for unrealized pnl*!/*/}
                    {/*/!*todo add cards for realized pnl*!/*/}
                    <Modal
                        open={openCancelPositions}
                        onClose={(e) => setOpenCancelPositions(false)}
                        closeIcon
                    >
                        <Modal.Header>Liquidate ALL open positions?</Modal.Header>
                        <Modal.Content>
                            <Button
                                basic
                                onClick={(e) => onClosePositions(e)}
                                color="red"
                                loading={closePositionsLoader}
                            >
                                <Button.Content visible>
                                    <Icon name='trash alternate outline'/> Yes!
                                </Button.Content>
                            </Button>
                        </Modal.Content>
                    </Modal>
                    {(error || success) && (
                        <Message
                            success={success}
                            error={error}
                            header={message.header}
                            content={message.content}
                            list={message.list}
                        />
                    )}
                    <div className={'control-panel'}>
                        <Header as='h3' textAlign={'center'} dividing>Bot quick actions</Header>
                        <Button
                            basic
                            animated='fade'
                            onClick={(e) => onPressCancelPositions()}
                            color="red"
                        >
                            <Button.Content hidden>
                                <Icon name='trash alternate outline'/>
                            </Button.Content>
                            <Button.Content visible>
                                <Icon name='trash alternate outline'/> Liquidate current positions
                            </Button.Content>
                        </Button>
                    </div>
                    <BotConfig config={config}/>
                </div>
            ) : ('')}

            {(isSending || _.isEmpty(config)) && (
                <Load text={"Loading Bot data"}/>
            )}
        </div>
    )
}

export default BotPage