import React, {useEffect, useState} from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Amplify, {Storage, Auth} from "aws-amplify";
import * as d3 from "d3";
import _ from 'lodash';
import Load from "./loader";

import awsconfig from '../aws-exports'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

const IndexLineGraph = ({op, user, eois}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [eoiIndexData, setEoiIndexData] = useState([])
    const [yAxis, setYAxis] = useState([])

    let dataSource = {
        chart: {
            multicanvas: false,
            labelDisplay: "wrap",
            theme: "fusion",
            showclosevalue: "1",
            showZeroPlane: "1",
            zeroPlaneAlpha: "50",
            zeroPlaneThickness: "3",
            decimals: "2",
            decimalSeparator: ".",
            thousandSeparator: ",",
            // formatNumberScale: "1",
            syncAxisLimits: "1",
            showopenvalue: "1",
            setadaptiveymin: "1"
        },
        caption: {
            text: "EOI Index"
        },
        legend: {
            enabled: "1",
            position: "bottom",
            alignment: "middle"
        },
        xAxis: {
            plot: ""
        },
        Series: 'Eoi',
        yaxis: yAxis
    };

    let [timeseriesDs, setTimeserieDs] = useState({
        type: "timeseries",
        renderAt: "container",
        width: "100%",
        height: "70%",
        dataSource,
        events: {
            drawComplete: function (eventObj, dataObj) {
                console.log("Draw completed:", eventObj);
                setIsFetching(false);
            },
            drawCancelled: function (ev) {
                console.log("Draw Cancelled:", ev);
                setIsFetching(false);
            }
        }
    })

    let chartSchema = [
        {
            "name": "Price Date",
            "type": "date",
            "format": "%Y-%m-%d %H:%M:%S"
        }
    ]

    const formatD3Data = (data) => {
        if (_.isEmpty(data)) return

        let price_items = []

        //  Group data by price date and create a column per EOI to use as independant YAxis
        let groups = _.groupBy(data, 'insert_date_price');

        // const timeserieData = Object.assign({}, timeseriesDs);

        _.forEach(groups, function (objs, date) {
            let date_item = [date]
            _.forEach(objs, function (eoi_price) {
                date_item.push(eoi_price.price)
            })
            price_items.push(date_item)


        });

        return price_items
    }

    useEffect(() => {
        if (_.isEmpty(op)) return

        const timeserieData = Object.assign({}, timeseriesDs);
        delete timeserieData.dataSource.data
        setTimeserieDs(timeserieData)

        try {

            if (op.op == 'added') {
                const go = async () => {
                    // Get price data from each EOI
                    const _key = `${op.eoi}/price_data.json`
                    const level = 'public'
                    const link = await Storage.get(_key, {level})

                    setIsFetching(true)

                    await d3.json(link).then(function (data) {
                        // Get previous eoi index data
                        let indexDataLocal = eoiIndexData.slice()

                        indexDataLocal = indexDataLocal.concat(data)

                        // Set EOI index data for further use
                        setEoiIndexData(indexDataLocal)
                    });
                }
                go()
            }

            if (op.op == 'removed') {
                let indexDataLocal = _.filter(eoiIndexData, obj => (obj.eoi != op.eoi));
                setEoiIndexData(indexDataLocal)
            }

        } catch (error) {
            setIsFetching(false)
            console.log('querry failed ->', error)
        }

    }, [op])

    useEffect(() => {
        if (_.isEmpty(eois)) return

        let files = []

        _.each(eois, function (eoi) {
            const _key = `${eoi}/price_data.json`
            const level = 'public'
            // const link = Storage.get(_key, {level})

            // files.push(d3.json(link))
            files.push(Storage.get(_key, {level}))
        })

        Promise.all(files).then(function (links) {
            // do stuff
            console.log(links);

            let d3_items = []
            _.each(links, function (link) {
                d3_items.push(d3.json(link))
            })

            Promise.all(d3_items).then(function (data) {
                // Get price data from each EOI
                let eoi_data = _.flatten(data)

                // Set EOI index data for further use
                setEoiIndexData(eoi_data)

                setIsFetching(false)
            }).catch(err => {
                console.log('Error parsing the price data ->', err)
            })

        }).catch(err => {
            console.log('Error getting the urls ->', err)
        })
    }, [eois])


    useEffect(() => {
        if (_.isEmpty(eoiIndexData)) return

        // Format data for D3 library
        let formatted_data = formatD3Data(eoiIndexData)

        // Time series data
        const timeserieData = Object.assign({}, timeseriesDs);

        // create yAxis confi for fusionchart config for the specific eoi. i.e: btc, eth, etc
        let eois = Object.keys(_.groupBy(eoiIndexData, 'eoi'))

        let chartSchema = [{
            "name": "Price Date",
            "type": "date",
            "format": "%Y-%m-%d %H:%M:%S"
        }]
        let plots = []

        _.forEach(eois, function (eoi){

            // Set chartSchema
            chartSchema.push({"name": eoi, "type": "number"})

            // Set timeseries yaxis plots
            plots.push({
                plot: [
                    {
                        value: eoi,
                        connectnulldata: true,
                        type: "line"
                    }
                ]
            })
        })

        // Price fusion table
        const priceFusionTable = new FusionCharts.DataStore().createDataTable(
            formatted_data,
            chartSchema
        );

        timeserieData.dataSource.data = priceFusionTable;
        timeserieData.dataSource.yaxis = plots;
        setTimeserieDs(timeserieData)

    }, [eoiIndexData])

    return (
        <div className={'eoi-index'}>
            {timeseriesDs.dataSource.data ? (
                <ReactFC {...timeseriesDs} />
            ) : (
                <div>
                    {(isFetching) && (
                        <Load text={"Loading EOI index ..."}/>
                    )}
                </div>
            )}
        </div>
    )
}

export default IndexLineGraph