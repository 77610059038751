import React, {useEffect, useState} from "react";
import Amplify, {Storage, API, graphqlOperation, Auth} from "aws-amplify";
import * as d3 from "d3";
import _ from 'lodash';
import Load from "./loader";
import {Table, Pagination, Input, Grid} from 'semantic-ui-react'


import awsconfig from '../aws-exports'
import {getItems} from '../graphql/queries'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const ItemTable = ({items}) => {
    const [baseData, setBaseData] = useState(items)
    const [pagedData, setPagedData] = useState([])
    const [data, setData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState(0)
    const [itemFilters, setItemFilters] = useState({ author: "", sentiment: "", score: 0})
    const [sortColumn, setSortColumn] = useState('insert_date')
    const [direction, setDirection] = useState('descending')
    const [isSending, setIsSending] = useState(false)
    const header = [
        {
            "name": "author",
            "width": 2
        },
        {
            "name": "body",
            "width": 2
        },
        {
            "name": "url",
            "width": 1
        },
        {
            "name": "label",
            "width": 1
        },
        {
            "name": "score",
            "width": 1
        },
        {
            "name": "insert_date",
            "width": 1
        }
    ]

    const handlePageClick = (e, {activePage}) => setActivePage(activePage)

    const filterData = (data) => {
        if (_.isEmpty(data)) return

        let filtered_data = _.filter(data, obj =>
            obj.author.toLowerCase().includes(itemFilters.author.toLowerCase()) &&
            obj.label.toLowerCase().includes(itemFilters.sentiment.toLowerCase()) &&
            parseFloat(obj.score) >= itemFilters.score
        )

        return filtered_data
    }

    useEffect(() => {
        if (_.isEmpty(baseData)) return

        const filteredData = filterData(baseData)
        let newPagedData = _.chunk(filteredData, 100)
        setPagedData(newPagedData)
        setPages(newPagedData.length)
        setData(newPagedData[0])

    }, [itemFilters])

    useEffect(() => {
        if (_.isEmpty(pagedData)) return

        setData(pagedData[activePage - 1])

    }, [activePage])

    // start a query
    useEffect(() => {
        if (isSending || _.isEmpty(items)) return

        setIsSending(true)

        let words = _.chunk(items, 100)
        let pages = words.length

        setBaseData(items)
        setPagedData(words)
        setPages(pages)
        setIsSending(false)
        setData(words[0])

    }, [items, isSending])

    const sortTable = (column) => {
        let direction = 'ascending'
        let orderedData = []

        if (column === sortColumn) {
            orderedData = baseData.slice().reverse()
            direction = direction === 'ascending' ? 'descending' : 'ascending'
        } else {
            orderedData = _.sortBy(baseData, [column])
        }

        let newPagedData = _.chunk(orderedData, 100)
        setPagedData(newPagedData)
        setSortColumn(column)
        setDirection(direction)
        setData(newPagedData[0])
    }

    return (
        <div>
            {!_.isEmpty(baseData) ? (
                <div className={'table-container'}>
                    <div className={'filter-container'}>
                        <Grid stackable columns={4} fixed padded>
                            <Grid.Column key={'Author'}>
                                <Input
                                    fluid
                                    label={{basic: true, content: 'Author'}}
                                    labelPosition='left'
                                    placeholder=''
                                    onChange={(e) => (setItemFilters({...itemFilters, author: e.target.value}))}
                                />
                            </Grid.Column>
                            <Grid.Column key={'Label'}>
                                <Input
                                    fluid
                                    label={{basic: true, content: 'Sentiment'}}
                                    labelPosition='left'
                                    placeholder=''
                                    onChange={(e) => (setItemFilters({...itemFilters, sentiment: e.target.value}))}
                                />
                            </Grid.Column>
                            <Grid.Column key={'Sentiment Prob'}>
                                <Input
                                    fluid
                                    label={{basic: true, content: 'Probability'}}
                                    labelPosition='left'
                                    placeholder=''
                                    onChange={(e) => (setItemFilters({
                                        ...itemFilters,
                                        score: e.target.value
                                    }))}
                                />
                            </Grid.Column>
                        </Grid>
                    </div>

                    {!_.isEmpty(data) && (
                        <Table sortable celled padded={true} size={'small'} fixed columns={header.length}
                               singleLine={false}>
                            <Table.Header>
                                <Table.Row>
                                    {
                                        header.map((column) => (
                                            <Table.HeaderCell
                                                key={column.name}
                                                width={column.width}
                                                sorted={column.name === sortColumn ? direction : null}
                                                onClick={() => sortTable(column.name)}
                                            >
                                                {column.name}
                                            </Table.HeaderCell>
                                        ))
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data.map((item, index) => (
                                    <Table.Row>
                                        <Table.Cell>{item.author}</Table.Cell>
                                        <Table.Cell>{item.body}</Table.Cell>
                                        <Table.Cell>{item.url}</Table.Cell>
                                        <Table.Cell>{item.label}</Table.Cell>
                                        <Table.Cell>{item.score}</Table.Cell>
                                        <Table.Cell>{item.insert_date}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={header.length}>
                                        <Pagination
                                            activePage={activePage}
                                            onPageChange={handlePageClick}
                                            totalPages={pages}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    )}
                </div>
            ) : (
                <div>
                    {(isSending) && (
                        <Load text={"Loading items..."}/>
                    )}
                </div>
            )}
        </div>
    )
}

export default ItemTable