import React, {useState} from "react";
import {Table, Button, Icon, Header, Modal} from 'semantic-ui-react'
import _ from 'lodash';
import Moment from 'react-moment';
import {API, graphqlOperation} from "aws-amplify";
import {removeAuthor, voteAuthor} from "../graphql/queries";
import AuthorForm from "./author-form";

const AuthorList = ({authors}) => {
    const [deletedItem, setDeletedItem] = useState({id: null, integration_id: null})
    const [voteUpItem, setVoteUpItem] = useState({id: null, integration_id: null})
    const [voteDownItem, setVoteDownItem] = useState({id: null, integration_id: null})
    const [baseAuthors, setAuthors] = useState(authors)
    const [openAddAuthor, setOpenAddAuthor] = useState(false)

    const onPressAdd = () => {
        // e.preventDefault()
        setOpenAddAuthor(!openAddAuthor)
    }

    const onDelete = (e, author) => {
        // e.preventDefault()

        if (deletedItem.id) return

        setDeletedItem(author)
        const go = async (author) => {

            try {
                let updated_authors = []
                const result = await API.graphql(
                    graphqlOperation(removeAuthor, {
                        "author": author.id,
                        "integration": author.integration_id
                    })
                )

                let body = JSON.parse(result.data.removeAuthor.body)
                console.log(`Get Authors : ${result.data.removeAuthor.statusCode} - ${body.message}`)
                if (result.data.removeAuthor.statusCode === "200") {
                    updated_authors = _.filter(baseAuthors, obj =>
                        (obj.integration_id !== author.integration_id || obj.id !== author.id)
                    )
                    setAuthors(updated_authors)
                }
                setDeletedItem({id: null, integration_id: null})

            } catch (error) {
                setDeletedItem({id: null, integration_id: null})
                console.log('querry failed ->', error)
            }
        }
        go(author)
    }

    const onVote = (e, author, vote) => {
        e.preventDefault()

        if (voteUpItem.id || voteDownItem.id) return

        vote === 1 ? setVoteUpItem(author) : setVoteDownItem(author)
        const go = async (author, vote) => {

            try {
                const result = await API.graphql(
                    graphqlOperation(voteAuthor, {
                        "author": author.id,
                        "integration": author.integration_id,
                        "vote": vote
                    })
                )

                let body = JSON.parse(result.data.voteAuthor.body)
                console.log(`Vote Author : ${result.data.voteAuthor.statusCode} - ${body.message}`)
                if (result.data.voteAuthor.statusCode === "200") {
                    let index = _.findIndex(baseAuthors, {id: author.id, integration_id: author.integration_id});
                    if (vote === 1) {
                        // update user_vote_up attr
                        author.user_vote_up += 1
                    }

                    if (vote === -1) {
                        //update user_vote_down attr
                        author.user_vote_down += 1
                    }
                    // Replace item at index using native splice
                    baseAuthors.splice(index, 1, author);
                    setAuthors(baseAuthors)
                }
                vote === 1 ? setVoteUpItem({id: null, integration_id: null}) : setVoteDownItem({
                    id: null,
                    integration_id: null
                })


            } catch (error) {
                vote === 1 ? setVoteUpItem({id: null, integration_id: null}) : setVoteDownItem({
                    id: null,
                    integration_id: null
                })
                console.log('querry failed ->', error)
            }
        }
        go(author, vote)
    }

    return (
        <div>
            <Table
                size={'small'}
                compact={'very'}
                basic={'very'}
                padded={false}
                unstackable
                fixed
            >
                <Table.Body>
                    {baseAuthors.map((item, index) => (
                        <Table.Row>
                            <Table.Cell singleLine={false} width={4}>
                                <Header as='h5'>
                                    <Header.Content>
                                        {item.name}
                                        <Header.Subheader>
                                            ({item.integration_id}) <br/>
                                            {item.id}
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Table.Cell>
                            <Table.Cell>
                                <span className={'row'}>
                                    <span>Global</span>
                                </span>
                                <span className={'row'}>
                                    <Icon name='arrow up' color={'green'}/>
                                    {item.vote_up}
                                </span>
                                <span className={'row'}>
                                    <Icon name='arrow down' color={'red'}/>
                                    {item.vote_down}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                <span className={'row'}>Mine</span>
                                <span className={'row'}>
                                    <Icon name='arrow up' color={'green'}/>
                                    {item.user_vote_up}
                                </span>
                                <span className={'row'}>
                                    <Icon name='arrow down' color={'red'}/>
                                    {item.user_vote_down}
                                </span>
                            </Table.Cell>
                            <Table.Cell className={'mobile hidden'}>
                                <Moment fromNow ago>
                                    {item.insert_date}
                                </Moment> ago
                            </Table.Cell>
                            <Table.Cell className={'computer large screen only'}>
                                <Button.Group size={'mini'}>
                                    <Button
                                        loading={item.id === voteUpItem.id && item.integration_id === voteUpItem.integration_id}
                                        basic
                                        animated='fade'
                                        onClick={(e) => onVote(e, item, 1)}
                                        color="blue"
                                    >
                                        <Button.Content hidden>
                                            <Icon name='arrow up'/>
                                        </Button.Content>
                                        <Button.Content visible>
                                            <Icon name='thumbs up outline'/>
                                        </Button.Content>
                                    </Button>
                                    <Button
                                        loading={item.id === voteDownItem.id && item.integration_id === voteDownItem.integration_id}
                                        basic
                                        animated='fade'
                                        onClick={(e) => onVote(e, item, -1)}
                                        color="blue"
                                    >
                                        <Button.Content hidden>
                                            <Icon name='arrow down'/>
                                        </Button.Content>
                                        <Button.Content visible>
                                            <Icon name='thumbs down outline'/>
                                        </Button.Content>
                                    </Button>
                                </Button.Group>{' '}
                                <Button.Group size='mini'>
                                    <Button
                                        loading={item.id === deletedItem.id && item.integration_id === deletedItem.integration_id}
                                        basic
                                        animated='fade'
                                        onClick={(e) => onDelete(e, item)}
                                        color="red"
                                        circular
                                    >
                                        <Button.Content visible>
                                            <Icon name='trash alternate outline'/>
                                        </Button.Content>
                                        <Button.Content hidden>
                                            <Icon name='trash alternate outline'/>
                                        </Button.Content>
                                    </Button>
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell className={'mobile only tablet only'}>
                                <Button.Group size={'mini'}>
                                    <Button
                                        loading={item.id === voteUpItem.id && item.integration_id === voteUpItem.integration_id}
                                        onClick={(e) => onVote(e, item, 1)}
                                        color="blue"
                                        icon
                                        basic
                                        circular
                                    >
                                        <Icon name='thumbs up outline'/>
                                    </Button>
                                    <Button
                                        loading={item.id === voteDownItem.id && item.integration_id === voteDownItem.integration_id}
                                        onClick={(e) => onVote(e, item, -1)}
                                        color="blue"
                                        icon
                                        basic
                                        circular
                                    >
                                        <Icon name='thumbs down outline'/>
                                    </Button>
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell className={'mobile only tablet only'}>
                                <Button
                                    loading={item.id === deletedItem.id && item.integration_id === deletedItem.integration_id}
                                    onClick={(e) => onDelete(e, item)}
                                    color="red"
                                    icon
                                    basic
                                    size={'mini'}
                                >
                                    <Icon name='trash alternate outline'/>
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Button
                className={'fixed bottom right'}
                animated='fade'
                color={'green'}
                circular
                onClick={(e) => onPressAdd()}>
                <Button.Content visible><Icon name='add'/></Button.Content>
                <Button.Content hidden>Add</Button.Content>
            </Button>
            <Modal
                open={openAddAuthor}
                onClose={(e) => setOpenAddAuthor(false)}
                closeIcon
            >
                <Modal.Header>Add an Author</Modal.Header>
                <Modal.Content>
                    <AuthorForm authors={baseAuthors}/>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default AuthorList