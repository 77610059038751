import React, {useEffect, useState} from "react";
import Amplify, {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {I18n} from '@aws-amplify/core';
import _ from 'lodash';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import Load from "./loader";

import {strings as mainAppStrings} from './dictionary'
import SimulationTable from "./simulation-table";

import awsconfig from '../aws-exports'
import {getSimulation} from "../graphql/queries";
import * as d3 from "d3";

I18n.putVocabularies(mainAppStrings);

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const SimulationPage = ({simulation}) => {

    const [isSending, setIsSending] = useState(false)
    /*const [simulation, setSimulation] = useState([])

    const initSimulationQuery = async () => {
        try {
            const _key = `simulations/simulations.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setSimulation(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    useEffect(() => {
        if (!_.isEmpty(simulation)) return
        console.log('Fetch word cloud queries')

        initSimulationQuery()

    }, [])*/

    return (
        <div>
            {(!_.isEmpty(simulation)) ? (
                <SimulationTable baseData={simulation}/>
            ) : ('')}

            {(isSending || _.isEmpty(simulation)) && (
                <Load text={"Loading simulation data"}/>
            )}
        </div>
    )
}

export default SimulationPage