import React, {useEffect, useState} from 'react'
import Amplify, {API, graphqlOperation, Auth, Storage} from 'aws-amplify'
import {
    AmplifyGreetings,
    AmplifyAuthContainer,
    AmplifyAuthenticator, AmplifySignIn
} from '@aws-amplify/ui-react';
import {onAuthUIStateChange, AuthState} from '@aws-amplify/ui-components';
import _ from 'lodash';
import awsconfig from './aws-exports'
import {getAuthors, getBotConfig} from './graphql/queries'

// Custom components
import Load from './components/loader'
import WordCloudGraph from "./components/wordcloud-graph";
import AuthorList from "./components/author-list";
import PredictionPage from "./components/prediction-page";
import PredictionTable from "./components/prediction-live";
import SimulationPage from "./components/simulation-page";
import BotPage from "./components/bot-page";
import Home from "./components/home";

import {Menu} from 'semantic-ui-react'
import * as d3 from "d3";

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const AuthStateApp = () => {
    const [isSending, setIsSending] = useState(false)
    const [user, setUser] = useState(null)
    const [authState, setAuthState] = React.useState();
    const [baseData, setBaseData] = useState([])
    const [authors, setAuthors] = useState([])
    const [tabFilter, setTabFilter] = useState('bot')
    const [currentQuery, setCurrentQuery] = useState(null)
    const [eoiIndexCoins, setEoiIndexCoins] = useState([])
    const [predictedEois, setPredictedEois] = useState([])
    const [overallAccuracy, setOverallAccuracy] = useState([])
    const [perEOIAccuracy, setPerEOIAccuracy] = useState([])
    const wordCloudFilters = {delta: 0, eoi: ''}
    const [currentPredictions, setCurrentPredictions] = useState([])
    const [currentPredictionsV2Down, setCurrentPredictionsV2Down] = useState([])
    const [currentPredictionsV2Up, setCurrentPredictionsV2Up] = useState([])
    const [simulation, setSimulation] = useState([])
    const [ids, setIds] = useState([])
    const [botConfig, setBotConfig] = useState({})


    useEffect(() => {
        if (!currentQuery || !currentQuery.file) return

        const go = async () => {
            const [level, _key] = currentQuery.file.key.split('/')
            const link = await Storage.get(_key, {level})

            await d3.csv(link).then(function (data) {
                setBaseData(data)
                setIsSending(false)
            });
        }
        go()
    }, [currentQuery])


    // start a query fr WordCloud
    const initStartQuery = async () => {
        if (isSending || !_.isEmpty(baseData)) return
        setIsSending(true)
        setCurrentQuery(null)

        try {

            const result = {
                "data": {
                    "startQuery": {
                        "id": "01539a51-331d-4ca4-8b35-2a2d4e5bf308",
                        "queryString": "SELECT * FROM delta_cloud",
                        "owner": "403c5822-71da-4d89-a6c8-eb27bdabb86d",
                        "status": "COMPLETED",
                        "file": {
                            "bucket": "aws-athena-query-results-bubble-dashboard190218-dev",
                            "region": "us-east-1",
                            "key": "public/latest_delta.csv"
                        },
                        "createdAt": "2021-09-07T02:32:46.032Z",
                        "updatedAt": null
                    }
                }
            }
            console.log(`Setting sub ID: ${result.data.startQuery.id}`)
            // setIsSending(false)
            setCurrentQuery(result.data.startQuery)
        } catch (error) {
            setIsSending(false)
            console.log('querry failed ->', error)
        }
    }

    // start a query fr WordCloud
    const initEoiIndex = async () => {
        try {
            const _key = 'top_eois.json'
            const level = 'public'
            const link = await Storage.get(_key, {level})

            await d3.json(link).then(function (data) {
                let eoiIndex = []
                _.each(data, function (eoi) {
                    eoiIndex.push(eoi[0])
                })
                setEoiIndexCoins(eoiIndex)
            });

        } catch (error) {
            console.log('querry failed ->', error)
        }

    }

    // Fetch eois for prediction
    const initEoiIPredicted = async () => {
        try {
            const _key = 'eoi_predicted.json'
            const level = 'public'
            const link = await Storage.get(_key, {level})

            await d3.json(link).then(function (data) {
                let predictedEois = []

                // Get EOIs available for prediction component
                _.each(data, function (eoi) {
                    predictedEois.push(eoi)
                })
                setPredictedEois(predictedEois)
            });

        } catch (error) {
            console.log('querry failed ->', error)
        }

    }

    // start a query
    const initAuthorQuery = async () => {
        if (isSending) return

        setIsSending(true)

        console.log('Fetch authors')

        try {

            let authors_resp = []
            const result = await API.graphql({
                query: getAuthors,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            let body = JSON.parse(result.data.getAuthors.body)
            console.log(`Get Authors : ${result.data.getAuthors.statusCode} - ${body.message}`)
            if (!_.isUndefined(body.data)) {
                authors_resp = body.data
            }

            setAuthors(authors_resp)
            setIsSending(false)

        } catch (error) {
            setIsSending(false)
            console.log('querry failed ->', error)
        }
    }

    // fetch Word Cloud data
    const initPredictionOverall = () => {
        let files = []
        const level = 'public'
        setIsSending(true)
        _.each(["UP", "NO-UP", "ALL"], function (direction) {
            _.each([1, 7, 30, 90], function (acc_range) {
                _.each([80, 85, 90, 95], function (prob_rate) {
                    let _key = `prediction_overall_${direction}_${acc_range}_${prob_rate}.json`
                    console.log(_key)
                    files.push(Storage.get(_key, {level}))
                })
            })
        })


        Promise.all(files).then(function (links) {
            // do stuff
            let d3_items = []
            _.each(links, function (link) {
                d3_items.push(d3.json(link))
            })
            Promise.all(d3_items).then(function (data) {
                // Get price data from each EOI
                let acc_data = _.flatten(data)

                // Set EOI index data for further use
                setOverallAccuracy(acc_data)

                setIsSending(false)
            }).catch(err => {
                console.log('Error getting the accuracy data ->', err)
            })
        }).catch(err => {
            console.log('Error fetching files ->', err)
        })
    }

    const initPredictionPerEOI = async () => {
        let files = []
        const level = 'public'
        setIsSending(true)

        _.each(["UP", "NO-UP", "ALL"], function (direction) {
            _.each([1, 7, 30, 90], function (acc_range) {
                _.each([80, 85, 90, 95], function (prob_rate) {
                    let _key = `prediction_per_coin_${direction}_${acc_range}_${prob_rate}.json`
                    // files.push(d3.json(link))
                    files.push(Storage.get(_key, {level}))
                })
            })
        })

        Promise.all(files).then(function (links) {
            // do stuff
            let d3_items = []
            _.each(links, function (link) {
                d3_items.push(d3.json(link))
            })
            Promise.all(d3_items).then(function (data) {
                // Get price data from each EOI
                let acc_data = _.flatten(data)

                // Set EOI index data for further use
                setPerEOIAccuracy(acc_data)

                setIsSending(false)
            }).catch(err => {
                console.log('Error getting the accuracy data ->', err)
            })
        }).catch(err => {
            console.log('Error fetching files ->', err)
        })
    }

    const handleAuthStateChange = (nextAuthState, authData) => {

        console.log(nextAuthState)

    }

    // start a query
    const initSimulationQuery = async () => {
        try {
            const _key = `simulations/simulations.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setSimulation(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    // start a query to get bot config
    const initGetBotConfig = async () => {
        if (isSending) return

        setIsSending(true)

        console.log('Fetch bot config')

        try {

            let resp = {}
            const result = await API.graphql({
                query: getBotConfig,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            let body = JSON.parse(result.data.getBotConfig.body)
            console.log(`Get Bot cconfiig : ${result.data.getBotConfig.statusCode} - ${body.message}`)
            if (!_.isUndefined(body.data)) {
                resp = body.data
            }

            setBotConfig(resp)
            setIsSending(false)

        } catch (error) {
            setIsSending(false)
            console.log('querry failed ->', error)
        }
    }

    // fetch Word Cloud data
    useEffect(() => {
        if (!user || !_.isEmpty(baseData)) return
        console.log('Fetch word cloud queries')
        initStartQuery()
        initAuthorQuery()
        initEoiIndex()
        initEoiIPredicted()
        // initPredictionOverall()
        // initPredictionPerEOI()
        // initCurrentPredictions()
        // initCurrentPredictionsV2Down()
        // initCurrentPredictionsV2Up()
        initSimulationQuery()
        initGetBotConfig()
    }, [user])

    useEffect(() => {
        // const go = async () => {
        //     try {
        //         const sess = await Auth.();
        //         setAuthState('SignedInd');
        //     } catch (e) {
        //         if (e !== "No current user") {
        //             alert(e);
        //         }
        //     }
        // }
        // go()


        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            if (nextAuthState == AuthState.SignedIn) {
                setUser(authData)
            }
        });


    }, []);

    // fetch Current predictions
    const initCurrentPredictions = async () => {
        try {
            const _key = `current_predictions_v1_direction_UP.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setCurrentPredictions(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    // fetch Current predictions
    const initCurrentPredictionsV2Down = async () => {
        try {
            const _key = `current_predictions_v2_direction_DOWN.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setCurrentPredictionsV2Down(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    // fetch Current predictions
    const initCurrentPredictionsV2Up = async () => {
        try {
            const _key = `current_predictions_v2_direction_UP.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setCurrentPredictionsV2Up(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    return authState === AuthState.SignedIn && user ? (
        <div className="App">
            <AmplifyGreetings username={user.username}></AmplifyGreetings>
            <section>
                {(!_.isEmpty(baseData)) ? (
                    //Tab for sources
                    <div className={'wordCloudSection'}>
                        <Menu pointing secondary compactl size={'small'}>
                            <Menu.Item
                                name='EOIs'
                                active={tabFilter === 'EOI'}
                                onClick={(e) => (setTabFilter('EOI'))}
                            />
                            <Menu.Item
                                name='Authors'
                                active={tabFilter === 'Authors'}
                                onClick={(e) => (setTabFilter('Authors'))}
                            />
                            {/*<Menu.Item
                                name='Accuracy Report'
                                active={tabFilter === 'AccuracyReport'}
                                onClick={(e) => (setTabFilter('AccuracyReport'))}
                            />
                            <Menu.Item
                                name='Live Predictions'
                                active={tabFilter === 'LivePredictions'}
                                onClick={(e) => (setTabFilter('LivePredictions'))}
                            />
                            <Menu.Item
                                name='Live Predictions v2 Up'
                                active={tabFilter === 'LivePredictionsV2Up'}
                                onClick={(e) => (setTabFilter('LivePredictionsV2Up'))}
                            />
                            <Menu.Item
                                name='Live Predictions v2 Down'
                                active={tabFilter === 'LivePredictionsV2Down'}
                                onClick={(e) => (setTabFilter('LivePredictionsV2Down'))}
                            />*/}
                            <Menu.Item
                                name='Simulations'
                                active={tabFilter === 'simulations'}
                                onClick={(e) => (setTabFilter('simulations'))}
                            />
                            <Menu.Item
                                name='Bot'
                                active={tabFilter === 'bot'}
                                onClick={(e) => (setTabFilter('bot'))}
                            />
                        </Menu>
                        <div>
                            {tabFilter === 'Authors' ? (
                                <AuthorList authors={authors}/>
                            ) : ('')}

                            {tabFilter === 'EOI' ? (
                                <WordCloudGraph baseData={baseData} filters={wordCloudFilters} user={user}
                                                eoiIndexCoins={eoiIndexCoins}/>
                            ) : ('')}
                            {tabFilter === 'AccuracyReport' ? (
                                <PredictionPage overallAccuracy={overallAccuracy} perEOIAccuracy={perEOIAccuracy}/>
                            ) : ('')}
                            {tabFilter === 'LivePredictions' ? (
                                <PredictionTable currentPredictions={currentPredictions} report_direction={"UP"}/>
                            ) : ('')}
                            {tabFilter === 'LivePredictionsV2Up' ? (
                                <PredictionTable currentPredictions={currentPredictionsV2Up} report_direction={"UP"}/>
                            ) : ('')}
                            {tabFilter === 'LivePredictionsV2Down' ? (
                                <PredictionTable currentPredictions={currentPredictionsV2Down}
                                                 report_direction={"DOWN"}/>
                            ) : ('')}
                            {tabFilter === 'simulations' ? (
                                <SimulationPage simulation={simulation}/>
                            ) : ('')}
                            {tabFilter === 'bot' ? (
                                <BotPage config={botConfig}/>
                            ) : ('')}

                        </div>
                    </div>
                ) : (
                    <div>
                        <Load text={"Loading initial data..."}/>
                    </div>
                )}
            </section>
        </div>
    ) : (
        <Home/>
    );
}

export default AuthStateApp;