import React, {useEffect, useState} from "react";
import Amplify, {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {I18n} from '@aws-amplify/core';
import _ from 'lodash';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import Load from "./loader";
import moment from "moment";
import {Header, Button, Icon, Label, Card, Feed, Checkbox, Form} from 'semantic-ui-react'

import {strings as mainAppStrings} from './dictionary'
import CustomTable from "./CustomTable/CustomTable";

import awsconfig from '../aws-exports'

I18n.putVocabularies(mainAppStrings);

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const PredictionPage = ({overallAccuracy, perEOIAccuracy}) => {
    // Overall predictions states
    const [overallPredFiltered, setOverallPredFiltered] = useState([])
    const [predDayFilter, setPredDayFilter] = useState(1)
    const [perEOILeaderboard, setPerEOILeaderboard] = useState([])
    const [directionForecast, setDirectionForecast] = useState('UP')
    const [headers, setHeaders] = useState([
        "prob_rate_from",
        "prob_rate_to",
        "count_1",
        "total_1",
        "accuracy_pct_1",
        "count_4",
        "total_4",
        "accuracy_pct_4",
        "count_12",
        "total_12",
        "accuracy_pct_12",
        "count_24",
        "total_24",
        "accuracy_pct_24"
    ])

    const [isSending, setIsSending] = useState(false)

    // Overall predictions functions
    const filterOverallPredictions = (data) => {

        if (_.isEmpty(data)) return

        // filter by accuracy range days
        let filtered_data = _.filter(data, obj =>
            obj.report_period_days == predDayFilter &&
            obj.direction_forecast == directionForecast
        )

        let pred_results = []
        let pred_correct = _.filter(filtered_data, {'correct': true})
        _.forEach(pred_correct, function (pred) {

            let pred_incorrect = _.find(filtered_data, {'correct': false, "prob_rate_from": pred.prob_rate_from})
            pred['total_1'] = pred.count_1 + pred_incorrect.count_1
            pred['accuracy_pct_1'] = _.round(parseFloat(pred.accuracy_pct_1), 2)
            pred['total_4'] = pred.count_4 + pred_incorrect.count_4
            pred['accuracy_pct_4'] = _.round(parseFloat(pred.accuracy_pct_4), 2)
            pred['total_12'] = pred.count_12 + pred_incorrect.count_12
            pred['accuracy_pct_12'] = _.round(parseFloat(pred.accuracy_pct_12), 2)
            pred['total_24'] = pred.count_24 + pred_incorrect.count_24
            pred['accuracy_pct_24'] = _.round(parseFloat(pred.accuracy_pct_24), 2)

            pred_results.push(pred)
        })

        return pred_results
    }

    const onSelectPrediction = (e, prediction) => {
        e.preventDefault()

        if (_.isEmpty(prediction)) return

        //  filter EOI predictions based on selected prediction attrs report_period_days, prob_rate_from,
        //  prob_rate_to. Group them by pred period (1, 4, 12, 24) and display a leaderboard
        let filtered_eoi_preds = _.filter(perEOIAccuracy, obj =>
            obj.report_period_days == prediction.report_period_days &&
            obj.prob_rate_from == prediction.prob_rate_from &&
            obj.direction_forecast == prediction.direction_forecast
        )

        // Get Correct predcitions
        // let pred_results = {"period_1": [], "period_4": [], "period_12": [], "period_24": []}
        let pred_results = [Array(0), Array(0), Array(0), Array(0)]
        let pred_correct = _.filter(filtered_eoi_preds, {'correct': true})
        _.forEach(pred_correct, function (pred) {
            let pred_incorrect = _.find(filtered_eoi_preds, {'correct': false, "eoi": pred.eoi})

            pred['total_1'] = pred.count_1 + pred_incorrect.count_1
            pred['total_4'] = pred.count_4 + pred_incorrect.count_4
            pred['total_12'] = pred.count_12 + pred_incorrect.count_12
            pred['total_24'] = pred.count_24 + pred_incorrect.count_24

            // Group by prediction period
            let p_array = pred_results[0]
            p_array.push({
                "period": 1,
                "eoi": pred.eoi,
                "prob_rate_from": pred.prob_rate_from,
                "prob_rate_to": pred.prob_rate_to,
                "accuracy": _.round(parseFloat(pred.accuracy_pct_1), 2),
                "count": pred.count_1,
                "total": pred.total_1
            })
            pred_results[0] = p_array

            p_array = pred_results[1]
            p_array.push({
                "period": 4,
                "eoi": pred.eoi,
                "prob_rate_from": pred.prob_rate_from,
                "prob_rate_to": pred.prob_rate_to,
                "accuracy": _.round(parseFloat(pred.accuracy_pct_4), 2),
                "count": pred.count_4,
                "total": pred.total_4
            })
            pred_results[1] = p_array

            p_array = pred_results[2]
            p_array.push({
                "period": 12,
                "eoi": pred.eoi,
                "prob_rate_from": pred.prob_rate_from,
                "prob_rate_to": pred.prob_rate_to,
                "accuracy": _.round(parseFloat(pred.accuracy_pct_12), 2),
                "count": pred.count_12,
                "total": pred.total_12
            })
            pred_results[2] = p_array

            p_array = pred_results[3]
            p_array.push({
                "period": 24,
                "eoi": pred.eoi,
                "prob_rate_from": pred.prob_rate_from,
                "prob_rate_to": pred.prob_rate_to,
                "accuracy": _.round(parseFloat(pred.accuracy_pct_24), 2),
                "count": pred.count_24,
                "total": pred.total_24
            })
            pred_results[3] = p_array
        })

        pred_results[0] = _.sortBy(pred_results[0], ['accuracy']).slice().reverse()
        pred_results[1] = _.sortBy(pred_results[1], ['accuracy']).slice().reverse()
        pred_results[2] = _.sortBy(pred_results[2], ['accuracy']).slice().reverse()
        pred_results[3] = _.sortBy(pred_results[3], ['accuracy']).slice().reverse()

        setPerEOILeaderboard(pred_results)
    }

    useEffect(() => {
        if (_.isEmpty(overallAccuracy)) return

        const go = async () => {
            let filtered_overall_predictions = filterOverallPredictions(overallAccuracy)
            setOverallPredFiltered(filtered_overall_predictions)
        }
        go()
    }, [overallAccuracy])

    useEffect(() => {
        if (_.isEmpty(overallAccuracy)) return

        const go = async () => {
            let filtered_overall_predictions = filterOverallPredictions(overallAccuracy)
            setOverallPredFiltered(filtered_overall_predictions)
        }
        go()
    }, [predDayFilter])

    useEffect(() => {
        if (_.isEmpty(directionForecast)) return

        const go = async () => {
            let filtered_overall_predictions = filterOverallPredictions(overallAccuracy)
            setOverallPredFiltered(filtered_overall_predictions)
        }
        go()
    }, [directionForecast])

    return (
        <div>
            {!_.isEmpty(overallPredFiltered) ? (
                <div className={'filter-container'}>
                    <Button.Group floated={'right'}>
                        <Button as='a' basic color={predDayFilter === 1 ? 'green' : 'blue'}
                                onClick={(e) => (setPredDayFilter(1))}>1D</Button>
                        <Button as='a' basic color={predDayFilter === 7 ? 'green' : 'blue'}
                                onClick={(e) => (setPredDayFilter(7))}>7D</Button>
                        <Button as='a' basic color={predDayFilter === 30 ? 'green' : 'blue'}
                                onClick={(e) => (setPredDayFilter(30))}>30D</Button>
                        <Button as='a' basic color={predDayFilter === 90 ? 'green' : 'blue'}
                                onClick={(e) => (setPredDayFilter(90))}>90D</Button>
                    </Button.Group>
                    <div>
                        <Label>
                            <Icon name='calendar alternate outline' color={'red'}/>
                            {moment(moment.utc(overallPredFiltered[0].report_period_from).toDate()).format('YYYY-MM-DD' +
                                ' HH:mm:ss')}
                        </Label>
                        -
                        <Label>
                            <Icon name='calendar alternate outline' color={'red'}/>
                            {moment(moment.utc(overallPredFiltered[0].report_period_to).toDate()).format('YYYY-MM-DD HH:mm:ss')}
                        </Label>
                    </div>
                </div>
            ) : ('')}
            {!_.isEmpty(overallPredFiltered) ? (
                <div>
                    <div className={'filter-container'}>
                        <b>Direction forecast:</b>
                        <Checkbox
                            className={'radiopick'}
                            radio
                            label={{children: 'up'}}
                            name='checkboxRadioGroup'
                            value='UP'
                            checked={directionForecast === 'UP'}
                            onChange={(e, data) => setDirectionForecast(data.value)}
                        />
                        <Checkbox
                            radio
                            className={'radiopick'}
                            label={{children: 'no-up'}}
                            name='checkboxRadioGroup'
                            value='NO-UP'
                            checked={directionForecast === 'NO-UP'}
                            onChange={(e, data) => setDirectionForecast(data.value)}
                        />
                        <Checkbox
                            radio
                            className={'radiopick'}
                            label={{children: 'All'}}
                            name='checkboxRadioGroup'
                            value='ALL'
                            checked={directionForecast === 'ALL'}
                            onChange={(e, data) => setDirectionForecast(data.value)}
                        />
                    </div>
                    <div className={'table-container'}>
                        <CustomTable
                            items={overallPredFiltered}
                            header={headers}
                            onDelete={(e, data) => console.log(data)}
                            onEdit={(e, data) => console.log(data)}
                            onSelect={onSelectPrediction}
                            actions={false}
                            pagination={true}
                            sortColumn='prob_rate_from'
                            direction={'descending'}
                        />
                    </div>

                </div>
            ) : ('')}
            <div className="ui divider"></div>

            {!_.isEmpty(overallPredFiltered) ? (
                <Card.Group centered textAlign={'center'} stackable={true}>
                    {
                        perEOILeaderboard.map((items, index) => (
                            <Card key={index}>
                                <Card.Content>
                                    <Card.Header>Period: {items[0].period} {items[0].period == 1 ? 'hr' : 'hrs'}</Card.Header>
                                    <Card.Meta>Prob.
                                        rate: {items[0].prob_rate_from} to {items[0].prob_rate_to}</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Feed>
                                        {items.map((item, index) => (
                                            <Feed.Event>
                                                <Feed.Label content={item.eoi}/>
                                                <Feed.Content>
                                                    <Feed.Date># Predictions ({item.count} / {item.total})</Feed.Date>
                                                    <Feed.Summary>
                                                        <Label color={item.accuracy >= 75 ? 'green' : 'red'} horizontal>
                                                            {item.accuracy}%
                                                        </Label>
                                                        Accuracy
                                                    </Feed.Summary>
                                                </Feed.Content>
                                            </Feed.Event>
                                        ))}
                                    </Feed>
                                </Card.Content>
                            </Card>
                        ))
                    }
                </Card.Group>
            ) : ('')}
            {(isSending) && (
                <Load text={"Loading prediction data"}/>
            )}
        </div>
    )
}

export default PredictionPage