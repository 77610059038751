import React, {useState} from "react";
import {Button, Icon, Modal} from 'semantic-ui-react'

import SignIn from "./signIn";
import ContactForm from "./contact-form";

import awsconfig from '../aws-exports'
import Amplify, {Auth} from "aws-amplify";
Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const Home = ({}) => {

    const [openLogin, setOpenLogin] = useState(false)
    const [openContact, setOpenContact] = useState(false)

    const onLogin = () => {
        // e.preventDefault()
        setOpenLogin(!openLogin)
    }

    const onContact = () => {
        // e.preventDefault()
        setOpenContact(!openContact)
    }

    return (
        <div class={'home'}>
            <div>
                <Button
                    className={'fixed top right'}
                    animated='fade'
                    color={'grey'}
                    basic
                    onClick={(e) => onLogin()}>
                    <Button.Content visible><Icon name='user outline'/>Login</Button.Content>
                    <Button.Content hidden>Login</Button.Content>
                </Button>
                <Modal
                    open={openLogin}
                    size={'small'}
                    onClose={(e) => setOpenLogin(false)}
                    closeIcon
                >
                    <Modal.Content>
                        <SignIn/>
                    </Modal.Content>
                </Modal>
            </div>
            <div className={'middle'}>
                <blockquote>
                    "The sibyls (αἱ Σῐ́βυλλαι, singular Σῐ́βυλλᾰ) were prophetesses or oracles in Ancient Greece. The
                    sibyls prophesied at holy sites. At first, there appears to have been only a single sibyl. By the
                    fourth century BC, there appear to have been at least three more, Phrygian, Erythraean, and
                    Hellespontine. By the first century BC, there were at least ten sibyls, located in Greece, Italy,
                    the Levant, and Asia Minor."
                    <br/>
                    <a href={'https://en.wikipedia.org/wiki/Sibyl'} target={'_blank'}>- Wikipedia</a>
                </blockquote>
                <Button
                    className={'fixed bottom right'}
                    animated='fade'
                    color={'grey'}
                    basic
                    onClick={(e) => onContact()}>
                    <Button.Content visible><Icon name='hand spock outline'/>Contact us</Button.Content>
                    <Button.Content hidden>Contact us</Button.Content>
                </Button>
                <Modal
                    open={openContact}
                    size={'small'}
                    onClose={(e) => setOpenContact(false)}
                    closeIcon
                >
                    <Modal.Content>
                        <ContactForm/>
                    </Modal.Content>
                </Modal>
            </div>
        </div>
    )
}

export default Home