export const strings = {
    en: {
        prob_rate_from: 'Prob. rate from',
        prob_rate_to: 'Prob. rate to',
        count_1: 'Count in 1hr',
        total_1: 'Total in 1hr',
        accuracy_pct_1: 'Accuracy in 1hr',
        count_4: 'Count in 4hrs',
        total_4: 'Total in 4hrs',
        accuracy_pct_4: 'Accuracy in 4hrs',
        count_12: 'Count 12hrs',
        total_12: 'Total in 12hrs',
        accuracy_pct_12: 'Accuracy in 12hrs',
        count_24: 'Count 24hrs',
        total_24: 'Total in 24hrs',
        accuracy_pct_24: 'Accuracy in 12hrs',
        report_period_days: 'Accuracy report period (days)',
        report_period_from: 'Accuracy range from',
        report_period_to: 'Accuracy range from'
    },
    es: {
        prob_rate_from: 'Prob. rate from',
        prob_rate_to: 'Prob. rate to',
        count_1: 'Count in 1hr',
        total_1: 'Total in 1hr',
        accuracy_pct_1: 'Accuracy in 1hr',
        count_4: 'Count in 4hrs',
        total_4: 'Total in 4hrs',
        accuracy_pct_4: 'Accuracy in 4hrs',
        count_12: 'Count 12hrs',
        total_12: 'Total in 12hrs',
        accuracy_pct_12: 'Accuracy in 12hrs',
        count_24: 'Count 24hrs',
        total_24: 'Total in 24hrs',
        accuracy_pct_24: 'Accuracy in 12hrs',
        report_period_days: 'Accuracy report period (days)',
        report_period_from: 'Accuracy range from',
        report_period_to: 'Accuracy range from'
    }
};