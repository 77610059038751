export const strings = {
  en: {
    type: 'Type',
    description: 'Description',
    action: 'Action',
    edit: 'Edit',
    delete: 'Delete',
    no_data_header: 'No elements to show.',
    no_data_content: 'Go add some using the form',
  },
  es: {
    type: 'Tipo',
    description: 'Descripción',
    action: 'Acción',
    edit: 'Editar',
    delete: 'Eliminar',
    no_data_header: 'No hay elementos para mostrar.',
    no_data_content: 'Ve al formulario y agrega uno',
  }
};