import React, {useEffect, useState} from "react";
import {Form, Message, FormInput, Popup, Icon, Dropdown} from 'semantic-ui-react'
import _ from 'lodash';
import {API, graphqlOperation} from "aws-amplify";
import {saveAuthor} from "../graphql/queries";

const AuthorForm = ({authors}) => {
    const [baseAuthors, setBaseAuthors] = useState(authors)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [inputs, setInputs] = useState({})
    const [disabled, setDisable] = useState(true)

    const authorFormFields = [
        {
            as: FormInput,
            label: 'Integrations',
            key: 'integration',
            id: 'integration',
            placeholder: 'Integration',
            required: true,
            type: 'Dropdown',
            error: false,
            hint: 'Select the platform',
            options: [
                {key: 'twitter', text: 'Twitter', value: 'twitter'}
            ]
        },
        {
            as: FormInput,
            label: 'Username',
            key: 'username',
            id: 'username',
            placeholder: 'Username',
            required: true,
            type: 'string',
            error: false,
            hint: 'Enter the username',
        },
    ];

    const onSave = (e, author) => {
        e.preventDefault()

        if (loading) return

        setLoading(true)

        const go = async (author) => {

            try {
                const result = await API.graphql(
                    graphqlOperation(saveAuthor, {
                        "author": inputs.username,
                        "integration": inputs.integration
                    })
                )

                let body = JSON.parse(result.data.saveAuthor.body)
                console.log(`Saved author : ${result.data.saveAuthor.statusCode} - ${body.message}`)
                if (result.data.saveAuthor.statusCode === "200") {
                    let message = {
                        header: 'Success',
                        content: body.message
                    }
                    setMessage(message)
                    setSuccess(true)
                    if (!body.message.includes('already')){
                        baseAuthors.push(body.data);
                        setBaseAuthors(baseAuthors)
                    }

                } else {
                    let message = {
                        header: 'Warning',
                        content: body.error
                    }
                    setMessage(message)
                    setError(true)
                }
                /*let author = {
                    "integration_id": "discord",
                    "id": "crazy4cryptos",
                    "name": "Crazy 4 Cryptos",
                    "vote_up": 100,
                    "vote_down": 15,
                    "user_vote_up": 2,
                    "user_vote_down": 1,
                    "insert_date": "2022-02-23 15:00:00"
                }
                baseAuthors.push(author);
                setBaseAuthors(baseAuthors);*/
                setLoading(false)


            } catch (error) {
                console.log('querry failed ->', error)
                message.header = 'Error'
                message.content = error
                setMessage(message)
                setError(true)
                setLoading(true)
            }
        }
        go(author)
    }

    const onChange = (e, d) => {
        e.preventDefault()

        if (!inputs) return

        const {id, value} = e.target;

        let form_inputs = inputs

        form_inputs = {
            ...form_inputs,
            [id]: value
        }
        setInputs(form_inputs);

    }

    const handleDropdownChange = (evt, option, key) => {
        evt.preventDefault();
        const {value, id} = option;
        let form_inputs = inputs;

        form_inputs = {
            ...form_inputs,
            [id]: value
        }
        setInputs(form_inputs);
    }

    const getInvalidFields = React.useCallback((inputs) => {

        let invalidsFields = [];

        _.map(authorFormFields, function (field) {
            if (field.required && !inputs[field.id]) {
                field.error = true;
                invalidsFields.push(`${field.label} is required`);
            } else {
                field.error = false;
            }
        })

        return invalidsFields;
    }, [])

    // fetch Word Cloud data
    useEffect(() => {
        if (!inputs) return

        let cleanForm = _.isEmpty(inputs);
        let invalids = cleanForm || getInvalidFields(inputs);

        if (invalids && !_.isEmpty(invalids)) {
            let message = {
                header: 'Validation error',
                list: invalids
            }

            setError(true)
            setDisable(true)
            setMessage(message)
        } else {
            setError(false)
            setDisable(cleanForm ? true : false)
        }

    }, [inputs, getInvalidFields])

    return (
        <div>
            <Form
                onSubmit={(e) => onSave(e)}
                loading={loading}
                error={error}
                success={success}
            >
                {(error || success) && (
                    <Message
                        success={success}
                        error={error}
                        header={message.header}
                        content={message.content}
                        list={message.list}
                    />
                )}
                {authorFormFields.map(field => {
                    if (field.type === 'Dropdown') {
                        return (
                            <Form.Field required={field.required}>
                                <label>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top left'
                                        hoverable={true}
                                    />)}
                                </label>
                                <Dropdown
                                    button
                                    basic
                                    fluid
                                    selection
                                    options={field.options}
                                    icon='angle down'
                                    placeholder={field.placeholder}
                                    id={field.id}
                                    onChange={handleDropdownChange}
                                    value={_.isUndefined(inputs) || _.isEmpty(inputs[field.id]) ? '' : inputs[field.id]}
                                    closeOnBlur={true}
                                />
                            </Form.Field>
                        )
                    }

                    return (
                        <Form.Input
                            {...field}
                            label={
                                <label>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top right'
                                        hideOnScroll
                                    />)}
                                </label>
                            }
                            value={_.isUndefined(inputs) || _.isEmpty(inputs[field.key]) ? '' : inputs[field.key]}
                            onChange={(e, d) => onChange(e, d)}
                        />
                    )
                })}
                <Form.Button
                    basic fluid
                    type={'submit'}
                    color={'green'}
                    content={'Save'}
                    disabled={disabled}
                />
            </Form>
        </div>
    )
}

export default AuthorForm;