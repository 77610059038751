import React, {useState} from "react";
import Amplify, {Auth} from "aws-amplify";
import {
    AmplifyAuthenticator,
    AmplifyAuthContainer,
    AmplifySignIn
} from '@aws-amplify/ui-react';

import awsconfig from '../aws-exports'
Amplify.configure(awsconfig)
Auth.configure(awsconfig)


const SignIn = ({}) => {

    return (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator>
                <AmplifySignIn slot="sign-in" hideSignUp={true}/>
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    )
}

export default SignIn