import React, {useEffect, useRef, useState} from "react";
import Amplify, {Auth, Storage} from "aws-amplify";
import _ from 'lodash';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import Load from "./loader";
import moment from "moment";
import {
    Table,
    Pagination,
    Input,
    Grid,
    Icon,
    Button,
    Message
} from 'semantic-ui-react'

import awsconfig from '../aws-exports'
import {CSVLink} from "react-csv";


Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const PredictionTable = ({currentPredictions, report_direction}) => {
    const [baseData, setBaseData] = useState([])
    const [pagedData, setPagedData] = useState([])
    const [data, setData] = useState([])
    const [downloadData, setDownloadData] = useState([])

    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState(0)
    const [period, setperiod] = useState(1)
    const [itemFilters, setItemFilters] = useState({
        direction_forecast: '',
        forecast_probability: 0,
        eoi: ''
    })
    const [sortColumn, setSortColumn] = useState('expiration_datetime')
    const [direction, setDirection] = useState('ascending')
    const [isSending, setIsSending] = useState(false)

    const csvLink = useRef()

    const header = [
        {
            "name": "eoi",
            "width": 1,
            "id": "eoi"
        },
        {
            "name": "Prediction date from",
            "width": 4,
            "id": "prediction_datetime_from"
        },
        {
            "name": "Prediction date for",
            "width": 4,
            "id": "prediction_datetime_for"
        },
        {
            "name": "Expiration date",
            "width": 3,
            "id": "expiration_datetime"
        },
        {
            "name": "Price at prediction",
            "width": 3,
            "id": "price_at_prediction"
        },
        {
            "name": "Predicted at",
            "width": 4,
            "id": "predicted_at"
        },
        {
            "name": "Direction",
            "width": 3,
            "id": "direction_forecast"
        },
        {
            "name": "Probability",
            "width": 2,
            "id": "forecast_probability"
        }
    ]

    const options = {
        // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: true,
        enableOptimizations: true,
        fontFamily: "sans-serif",
        fontSizes: [8, 64],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 1,
        rotations: 0,
        rotationAngles: [90, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 500
    };

    const handlePageClick = (e, {activePage}) => setActivePage(activePage)

    useEffect(() => {
        if (_.isEmpty(pagedData)) return

        setData(pagedData[activePage - 1])

    }, [activePage])

    const filterData = (data) => {
        if (_.isEmpty(data)) return

        let filtered_data = _.filter(data, obj =>
            obj.period === period &&
            `${obj.eoi}`.toLowerCase().includes(itemFilters.eoi.toLowerCase()) &&
            `${obj.direction_forecast}`.toLowerCase().startsWith(itemFilters.direction_forecast.toLowerCase()) &&
            parseFloat(obj.forecast_probability) >= itemFilters.forecast_probability
        )

        return filtered_data
    }

    useEffect(() => {
        if (_.isEmpty(baseData)) return

        const filteredData = filterData(baseData)

        let orderedData = _.sortBy(filteredData, [sortColumn])
        if (direction === 'descending') {
            orderedData = orderedData.slice().reverse()
        }

        let newPagedData = _.chunk(orderedData, 100)
        setDownloadData(orderedData)
        setPagedData(newPagedData)
        setPages(newPagedData.length)
        setActivePage(1)
        setData(newPagedData[0])

    }, [itemFilters])

    const sortTable = (column) => {
        let sortDirection = 'ascending'
        let dir = (direction === 'descending') ? 'desc' : 'asc'

        if (column === sortColumn) {

            dir = direction === 'ascending' ? 'desc' : 'asc'
            sortDirection = direction === 'ascending' ? 'descending' : 'ascending'
        }
        let orderedData = _.orderBy(baseData, [column], [dir])

        const filtered_data = filterData(orderedData)

        let newPagedData = _.chunk(filtered_data, 100)
        // setPages(newPagedData.length)
        setDownloadData(orderedData)
        setPagedData(newPagedData)
        setSortColumn(column)
        setDirection(sortDirection)
        setActivePage(1)
        setData(newPagedData[0])
    }

    useEffect(() => {
        if (_.isEmpty(baseData)) return

        const filteredData = filterData(baseData)

        // Order data
        let dir = (direction === 'descending') ? 'desc' : 'asc'
        let orderedData = _.orderBy(filteredData, [sortColumn], [dir])

        let words = _.chunk(orderedData, 100)
        let pages = words.length

        setDownloadData(orderedData)
        setPagedData(words)
        setDirection('ascending')
        setActivePage(1)
        setPages(pages)
        setIsSending(false)
        setData(words[0])

    }, [baseData])

    useEffect(() => {
        if (_.isEmpty(currentPredictions)) return

        setIsSending(true)

        // filter by period
        let filtered_data = _.filter(currentPredictions, {"period": period})

        setBaseData(filtered_data)

        setIsSending(false)

    }, [currentPredictions])

    useEffect(() => {
        if (!period) return

        setIsSending(true)

        // filter by period
        let filtered_data = _.filter(currentPredictions, {"period": period})

        setBaseData(filtered_data)

        setIsSending(false)

    }, [period])


    return (
        <div>
            <div className={'filter-container'}>
                <Button.Group floated={'right'}>
                    <Button as='a' basic color={period === 1 ? 'green' : 'blue'}
                            onClick={(e) => (setperiod(1))}>1hr</Button>
                    <Button as='a' basic color={period === 4 ? 'green' : 'blue'}
                            onClick={(e) => (setperiod(4))}>4hrs</Button>
                    <Button as='a' basic color={period === 12 ? 'green' : 'blue'}
                            onClick={(e) => (setperiod(12))}>12hrs</Button>
                    <Button as='a' basic color={period === 24 ? 'green' : 'blue'}
                            onClick={(e) => (setperiod(24))}>24hrs</Button>

                </Button.Group>
            </div>
            <div className="ui divider"></div>
            {(!_.isEmpty(currentPredictions) && !_.isEmpty(baseData)) ? (
                <div>
                    <Grid stackable columns={4} padded>
                        <Grid.Column key={'EOI'}>
                            <Input
                                fluid
                                label={{basic: true, content: 'EOI'}}
                                labelPosition='left'
                                placeholder=''
                                onChange={(e) => (setItemFilters({
                                    ...itemFilters,
                                    eoi: e.target.value
                                }))}
                            />
                        </Grid.Column>
                        <Grid.Column key={'Probability'}>
                            <Input
                                fluid
                                label={{basic: true, content: 'Probability'}}
                                labelPosition='left'
                                placeholder=''
                                onChange={(e) => (setItemFilters({
                                    ...itemFilters,
                                    forecast_probability: e.target.value
                                }))}
                            />
                        </Grid.Column>
                        <Grid.Column key={'Direction Forecast'}>
                            <Button.Group>
                                <Button as='a' basic
                                        color={itemFilters.direction_forecast === report_direction ? 'green' : 'blue'}
                                        onClick={(e) => (setItemFilters({
                                            ...itemFilters,
                                            direction_forecast: report_direction
                                        }))}>
                                    {report_direction}
                                </Button>
                                <Button as='a' basic
                                        color={itemFilters.direction_forecast === `NO-${report_direction}` ? 'green' : 'blue'}
                                        onClick={(e) => (setItemFilters({
                                            ...itemFilters,
                                            direction_forecast: `NO-${report_direction}`
                                        }))}>NO-{report_direction}</Button>
                                <Button as='a' basic color={itemFilters.direction_forecast === '' ? 'green' : 'blue'}
                                        onClick={(e) => (setItemFilters({
                                            ...itemFilters,
                                            direction_forecast: ''
                                        }))}>ALL</Button>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column key={'download'}>
                            <CSVLink
                                data={downloadData}
                                filename='predictions.csv'
                                ref={csvLink}
                                target='_blank'
                            >
                                <Button
                                    loading={_.isEmpty(downloadData)}
                                    basic
                                    size='medium'
                                    animated='fade'
                                    // onClick={(e) => onVote(e, item, 1)}
                                    color="blue"
                                >
                                    <Button.Content hidden>
                                        <Icon name='cloud download'/>
                                    </Button.Content>
                                    <Button.Content visible>
                                        Download
                                    </Button.Content>
                                </Button>
                            </CSVLink>
                        </Grid.Column>
                    </Grid>
                    {!_.isEmpty(data) ? (
                        <div className={'table-container'}>
                            <Table sortable celled padded={true} size={'small'} columns={header.length}
                                   singleLine={true} className={'prediction-table'}>
                                <Table.Header>
                                    <Table.Row>
                                        {
                                            header.map((column) => (
                                                <Table.HeaderCell
                                                    key={column.name}
                                                    width={column.width}
                                                    sorted={column.id === sortColumn ? direction : null}
                                                    onClick={() => sortTable(column.id)}
                                                >
                                                    {column.name}
                                                </Table.HeaderCell>
                                            ))
                                        }
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.map((item, index) => (
                                        <Table.Row key={'index-' + index}>
                                            <Table.Cell>{item.eoi}</Table.Cell>
                                            <Table.Cell>{moment(item.prediction_datetime_from).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                            <Table.Cell>{moment(item.prediction_datetime_for).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                            <Table.Cell>{moment(item.expiration_datetime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                            <Table.Cell>{item.price_at_prediction}</Table.Cell>
                                            <Table.Cell>{moment(item.predicted_at).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                            <Table.Cell>{item.direction_forecast}</Table.Cell>
                                            <Table.Cell>{item.forecast_probability}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={header.length}>
                                            <Pagination
                                                activePage={activePage}
                                                onPageChange={handlePageClick}
                                                totalPages={pages}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </div>
                    ) : (
                        <Message warning attached>
                            <p>No data available</p>
                        </Message>)}
                </div>
            ) : (
                <Message warning attached>
                    <p>No data available</p>
                </Message>)}
            {(isSending) && (
                <Load text={"Loading current predictions data"}/>
            )}
        </div>
    )
}

export default PredictionTable