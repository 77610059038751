import React, {useEffect, useState} from "react";
import {Label, Input, Message, Header, Dropdown, Button} from 'semantic-ui-react'
import _ from 'lodash';
import ReactWordcloud from 'react-wordcloud';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import ItemPriceLineGraph from "./wordcloud-price-graph";
import SentimentGraph from "./wordcloud-sentiment-graph";
import IndexLineGraph from "./eoi-index-graph";


const WordCloudGraph = ({baseData, filters, user, eoiIndexCoins}) => {
    const [data, setData] = useState([])
    const [cloudFilters, setCloudFilters] = useState({...filters})
    const [eoiIndexFilters, setEoiIndexFilters] = useState(eoiIndexCoins)
    const [eoiIndexOp, setEoiIndexOp] = useState({})
    const [isFetching, setIsFetching] = useState(false)
    const [eoi, setEoi] = useState('')
    const [eois, setEois] = useState([])
    const [pPrice, setPPrice] = useState('1D')
    const options = {
        // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: true,
        enableOptimizations: true,
        fontFamily: "sans-serif",
        fontSizes: [8, 64],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 1,
        rotations: 0,
        rotationAngles: [90, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 500
    };
    const callbacks = {
        onWordClick: (word) => {
            // Fin EOI with all its attrs to get hour
            let p_hours = word.key.split("-")[1]
            let eoi = word.key.split("-")[0]
            let eoi_obj = _.find(baseData, {eoi: eoi, p_hours: p_hours})
            setEoi(eoi_obj)

            // Add to EOI Index
            let eois_index = eoiIndexFilters
            eois_index.push(eoi)
            setEoiIndexFilters(eois_index)
            setEoiIndexOp({"op": "added", "eoi": eoi})
        }
    };

    const filterData = (data) => {
        if (_.isEmpty(data)) return

        let words = []
        let eois = []
        let filtered_data = _.filter(data, obj => (
            parseFloat(obj.delta) >= cloudFilters.delta &&
            obj.eoi.toLowerCase().includes(cloudFilters.eoi.toLowerCase())))
        _.forEach(filtered_data, function (item) {
            words.push({text: `${item.eoi}-${item.p_hours}`, value: parseFloat(item.delta), key: `${item.eoi}-${item.p_hours}`})
            eois.push({text: item.eoi, value: item.eoi, key: `${item.eoi}-${item.p_hours}`})
        });
        return {"words": words, "eois": eois}
    }

    const handleDropdownChange = (evt, data) => {
        evt.preventDefault();
        let new_item = _.difference(data.value, eoiIndexFilters)
        let removed_item = _.difference(eoiIndexFilters, data.value)
        let index_op = {}
        if (!_.isEmpty(new_item)) {
            index_op = {"op": "added", "eoi": new_item[0]}
        }

        if (!_.isEmpty(removed_item)) {
            index_op = {"op": "removed", "eoi": removed_item[0]}
        }
        setEoiIndexFilters(data.value)
        setEoiIndexOp(index_op)
    }

    useEffect(() => {
        if (_.isEmpty(eoiIndexFilters)) return

        const go = async () => {
            let result = filterData(baseData)
            setData(result["words"])
            setEois(result["eois"])
        }
        go()
    }, [eoiIndexFilters])

    useEffect(() => {
        if (_.isEmpty(baseData)) return

        const result = filterData(baseData)
        setData(result['words'])

    }, [cloudFilters])

    useEffect(() => {
        if (!baseData) return
        const go = async () => {
            let result = filterData(baseData)
            setData(result["words"])
            setEois(result["eois"])
        }
        go()
    }, [baseData])

    return (
        <div>
            <div>
                {_.isEmpty(eoi) ? (
                    <Message warning attached>
                        <p>Select an EOI from the word cloud below.</p>
                    </Message>) : ('')}
                <Input
                    label={{basic: true, content: 'Delta'}}
                    labelPosition='left'
                    floated={'left'}
                    type={'number'}
                    placeholder='Delta value...'
                    onChange={(e) => (setCloudFilters({...cloudFilters, delta: e.target.value}))}
                />
                <Input
                    label={{basic: true, content: 'EOI'}}
                    labelPosition='left'
                    floated={'left'}
                    type={'text'}
                    placeholder='EOI symbol...'
                    onChange={(e) => (setCloudFilters({...cloudFilters, eoi: e.target.value}))}
                />
                {!_.isEmpty(eoi) ? (
                    <Label as='a' color='teal' tag size={'large'} hide={eoi}>
                        {eoi.eoi}
                        <Label.Detail>{eoi.value} delta | {eoi.p_hours} hr P </Label.Detail>
                    </Label>
                ) : ('')}
            </div>
            <ReactWordcloud
                options={options}
                words={data}
                callbacks={callbacks}
            />

            {/*Price graph section*/}
            <Button.Group floated={'right'}>
                <Button as='a' basic color={pPrice === '1D' ? 'green' : 'blue'}
                        onClick={(e) => (setPPrice('1D'))}>1D</Button>
                <Button as='a' basic color={pPrice === '7D' ? 'green' : 'blue'}
                        onClick={(e) => (setPPrice('7D'))}>7D</Button>
                <Button as='a' basic color={pPrice === '1M' ? 'green' : 'blue'}
                        onClick={(e) => (setPPrice('1M'))}>1M</Button>
                <Button as='a' basic color={pPrice === '3M' ? 'green' : 'blue'}
                        onClick={(e) => (setPPrice('3M'))}>3M</Button>
            </Button.Group>
            <ItemPriceLineGraph eoi={eoi} user={user} pPrice={pPrice}/>
            <SentimentGraph eoi={eoi} pPrice={pPrice}/>

            <div className={'control-panel'}>
                <Header as='h3' textAlign={'center'} dividing>EOI Index</Header>
                <Dropdown
                    search
                    selection
                    multiple
                    options={eois}
                    placeholder={'select EOIs...'}
                    id={'eoi_index'}
                    onChange={handleDropdownChange}
                    value={eoiIndexFilters}
                    disabled={_.isEmpty(eois)}
                    loading={isFetching}
                    closeOnBlur={true}
                />
            </div>
            <IndexLineGraph op={eoiIndexOp} user={user} eois={eoiIndexCoins}/>
        </div>
    )
}

export default WordCloudGraph