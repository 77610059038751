import React, {useEffect, useState} from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Amplify, {Storage, Auth} from "aws-amplify";
import * as d3 from "d3";
import _ from 'lodash';
import moment from 'moment';
import Load from "./loader";

import awsconfig from '../aws-exports'
import {Input, Button} from "semantic-ui-react";

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

const ItemPriceLineGraph = ({eoi, pPrice}) => {
    const [baseData, setBaseData] = useState([])
    const [priceBaseData, setPriceBaseData] = useState([])
    const [isSending, setIsSending] = useState(false)

    let dataSource = {
        chart: {
            multicanvas: false,
            labelDisplay: "wrap",
            theme: "fusion",
            showZeroPlane: "1",
            zeroPlaneAlpha: "50",
            zeroPlaneThickness: "3",
            decimals: "2",
            decimalSeparator: ".",
            thousandSeparator: ",",
            formatNumberScale: "1",
            syncAxisLimits: '1'
        },
        caption: {
            text: "Price Graph"
        },
        legend: {
            enabled: "1",
            position: "bottom",
            alignment: "middle"
        },
        xAxis: {
            plot: "Price Date"
        },
        yaxis: [
            {
                plot: [
                    {
                        value: "Price",
                        connectnulldata: true,
                        type: "line"
                    }
                ],
            },
            {
                plot: [
                    {
                        value: "Market Cap",
                        connectnulldata: true,
                        type: "line"
                    }
                ]
            },
            {
                plot: [
                    {
                        value: "Delta-1hr",
                        connectnulldata: true,
                        type: "line"
                    },
                    {
                        value: "Delta-2hrs",
                        connectnulldata: true,
                        type: "line"
                    },
                    {
                        value: "Delta-4hrs",
                        connectnulldata: true,
                        type: "line"
                    },
                    {
                        value: "Delta-8hrs",
                        connectnulldata: true,
                        type: "line"
                    }
                ]
            }
        ]
    };

    let [timeseriesDs, setTimeserieDs] = useState({
        type: "timeseries",
        renderAt: "container",
        width: "100%",
        height: "80%",
        dataSource,
        events: {
            drawComplete: function (eventObj, dataObj) {
                console.log("Draw completed:", eventObj);
                setIsSending(false);
            },
            drawCancelled: function (ev) {
                console.log("Draw Cancelled:", ev);
                setIsSending(false);
            }
        }
    })

    const chartSchema = [
        {
            "name": "Price Date",
            "type": "date",
            "format": "%Y-%m-%d %H:%M:%S"
        },
        {
            "name": "Price",
            "type": "number"
        },
        {
            "name": "Market Cap",
            "type": "number"
        },
        {
            "name": "Delta-1hr",
            "type": "number"
        },
        {
            "name": "Delta-2hrs",
            "type": "number"
        },
        {
            "name": "Delta-4hrs",
            "type": "number"
        },
        {
            "name": "Delta-8hrs",
            "type": "number"
        }

    ]

    const parse_p_price = () => {

        let from_date = ''
        switch (pPrice) {
            case '1D':
                from_date = moment().add(-1, 'day').format('YYYY-MM-DD HH:00:00')
                break;
            case '7D':
                from_date = moment().add(-7, 'days').format('YYYY-MM-DD HH:00:00')
                break;
            case '1M':
                from_date = moment().add(-1, 'month').format('YYYY-MM-DD HH:00:00')
                break;
            case '3M':
                from_date = moment().add(-3, 'month').format('YYYY-MM-DD HH:00:00')
                break;
            default:
                from_date = moment().add(-1, 'day').format('YYYY-MM-DD HH:00:00')
        }

        return from_date

    }
    // Return an array of delta periods where items are not overlapped (end date <= next item start date)
    const filter_periods = (items) => {

        let deltas = [items.pop()]
        items = _.reverse(items)

        _.forEach(items, function (item) {

            // Calculate P end date based on selected period (1,2,4,8)
            // let item_p_start_date = moment(item.p_start_date).format('YYYY-MM-DD HH:00:00')
            let item_p_end_date = moment(item.p_end_date).format('YYYY-MM-DD HH:00:00')
            let delta_p_start_date = moment(_.last(deltas).p_start_date).format('YYYY-MM-DD HH:00:00')

            if (item_p_end_date > delta_p_start_date && items.length > 1) return;

            deltas.push(item);
        });

        return deltas
    }

    const filterData = (data, price_data, date_from) => {
        if (_.isEmpty(data)) return

        let delta_price_items = []

        // Filter data by starting date
        let filtered_data = _.filter(data, obj => (obj.p_start_date >= date_from))

        // // group by p hours
        // let p_groups = _.groupBy(filtered_data, 'p_hours');
        //
        // // new delta array; will have deltas out of the start/end period of other deltas, removing hourly deltas
        // let deltas = []
        // _.forEach(p_groups, function (objs, p_hour) {
        //     deltas.push(filter_periods(objs))
        // })
        // deltas = _.flatten(deltas)

        // ilter items by sentiment threshold
        let price_items = _.filter(price_data, obj => (obj.insert_date_price >= date_from))

        _.forEach(price_items, function (price) {
            // Items data: filter items within the p start (p_start_date) and end date (p_end_date)
            let p_end_date = moment(price.insert_date_price).add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            let found_deltas = _.filter(filtered_data, obj => (obj.p_end_date >= price.insert_date_price && obj.p_end_date < p_end_date))

            // check delta for 1, 2, 4, 8 hrs
            let p_groups = _.groupBy(found_deltas, 'p_hours')

            let delta_1 = null, delta_2 = null, delta_3 = null, delta_4 = null;

            if (!_.isEmpty(found_deltas)) {
                delta_1 = Object.keys(p_groups).includes("1") ? p_groups["1"][0].delta : 0;
                delta_2 = Object.keys(p_groups).includes("2") ? p_groups["2"][0].delta : 0;
                delta_3 = Object.keys(p_groups).includes("4") ? p_groups["4"][0].delta : 0;
                delta_4 = Object.keys(p_groups).includes("8") ? p_groups["8"][0].delta : 0;
            }
            delta_price_items.push([price.insert_date_price, price.price, price.market_cap, parseFloat(delta_1), parseFloat(delta_2), parseFloat(delta_3), parseFloat(delta_4)])

        })
        return delta_price_items
    }

    const getPrice = async (eoi) => {
        try {
            const _key = `${eoi}/price_data.json`
            const level = 'public'
            const link = await Storage.get(_key, {level})

            setIsSending(true)

            await d3.json(link).then(function (data) {

                // Set base data
                setPriceBaseData(data)

                // Set EOI index data for D3
                setIsSending(false)
            });

        } catch (error) {
            console.log('querry failed ->', error)
            setIsSending(false)
        }
    }

    useEffect(() => {
        if (_.isEmpty(priceBaseData) || _.isEmpty(baseData)) return

        setIsSending(true)

        const timeserieData = Object.assign({}, timeseriesDs);
        delete timeserieData.dataSource.data

        let delta_price_items = filterData(baseData, priceBaseData, parse_p_price())

        if (!_.isEmpty(delta_price_items)) {
            // Price fusion table
            const priceFusionTable = new FusionCharts.DataStore().createDataTable(
                delta_price_items,
                chartSchema
            );

            // Time series data
            timeserieData.dataSource.data = priceFusionTable;
        }

        setTimeserieDs(timeserieData)

        setIsSending(false)

    }, [priceBaseData])

    useEffect(() => {
        if (isSending || _.isEmpty(pPrice) || _.isEmpty(baseData) || _.isEmpty(priceBaseData)) return

        const timeserieData = Object.assign({}, timeseriesDs);
        delete timeserieData.dataSource.data

        let delta_price_items = filterData(baseData, priceBaseData, parse_p_price())

        if (!_.isEmpty(delta_price_items)) {
            // Price fusion table
            const priceFusionTable = new FusionCharts.DataStore().createDataTable(
                delta_price_items,
                chartSchema
            );

            // Time series data
            timeserieData.dataSource.data = priceFusionTable;
        }
        setTimeserieDs(timeserieData)

    }, [pPrice])

    // start a query
    useEffect(() => {
        if (isSending || !eoi) return

        setIsSending(false)

        const go = async () => {
            try {
                let eoi_text = eoi.eoi

                const _key = `${eoi_text}/delta_data.json`
                const level = 'public'
                const link = await Storage.get(_key, {level})

                let filteredData = []

                await d3.json(link).then(function (data) {
                    setBaseData(data)
                    getPrice(eoi.eoi)
                });

            } catch (error) {
                setIsSending(false)
                console.log('querry failed ->', error)
            }
        }
        go()
    }, [eoi])

    return (
        <div className={'eoi-price'}>
            {timeseriesDs.dataSource.data ? (
                <div>
                    <ReactFC {...timeseriesDs} />
                </div>
            ) : ('')}
            {(isSending) && (
                <Load text={"Loading price and delta..."}/>
            )}
        </div>
    )
}

export default ItemPriceLineGraph