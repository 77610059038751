import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Icon, Table} from "semantic-ui-react";
import {I18n} from "@aws-amplify/core";
import Moment from 'react-moment';

const TableItem = ({onEdit, onDelete, onSelect, item, tableHeader, deleteItemId, actions}) => {

    return (
        <Table.Row key={item.id} onClick={(e) => onSelect(e, item)}>
            {
                tableHeader.map((column) => (
                    <Table.Cell key={column + item.id}>
                        {column.includes('date') ? (
                            <Moment calendar>
                                {item[column]}
                            </Moment>
                        ) : item[column]}
                    </Table.Cell>
                ))
            }
            {
                actions && (
                    <Table.Cell>
                        <Button.Group size='small' vertical>
                            <Button basic animated='fade' onClick={(e) => onEdit(e, item.id)} color="blue">
                                <Button.Content visible>{I18n.get('edit')}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='edit outline'/>
                                </Button.Content>
                            </Button>
                            <Button
                                loading={deleteItemId === item.id}
                                basic
                                animated='fade'
                                onClick={(e) => onDelete(e, item.id)}
                                color="red"
                            >
                                <Button.Content visible>{I18n.get('delete')}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='trash alternate outline'/>
                                </Button.Content>
                            </Button>

                        </Button.Group>
                    </Table.Cell>
                )
            }
        </Table.Row>

    )
}

TableItem.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    tableHeader: PropTypes.array.isRequired,
}

export default TableItem;