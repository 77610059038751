import React, {useEffect, useState} from 'react';
import {Table, Icon, Header, Pagination} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import {I18n} from "@aws-amplify/core";
import {strings as mainAppStrings} from './dictionary'
import TableItem from "./TableItem";

I18n.putVocabularies(mainAppStrings);

const CustomTable = ({
                         items,
                         onEdit,
                         onDelete,
                         onSelect,
                         header,
                         deleteItemId,
                         actions,
                         pagination,
                         sortColumn,
                         direction
                     }) => {

    const [pagedData, setPagedData] = useState([])
    const [data, setData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState(0)
    const [localSortColumn, setSortColumn] = useState(sortColumn)
    const [localDirection, setDirection] = useState(direction)

    const handlePageClick = (e, {activePage}) => setActivePage(activePage)

    useEffect(() => {
        if (_.isEmpty(pagedData)) return

        setData(pagedData[activePage - 1])

    }, [activePage])

    useEffect(() => {
        if (_.isEmpty(items)) return

        let orderedData = _.sortBy(items, [sortColumn])
        if (direction === 'descending') {
            orderedData = orderedData.slice().reverse()
        }
        let newPagedData = _.chunk(orderedData, 100)
        // setPages(newPagedData.length)
        setPagedData(newPagedData)
        setSortColumn(sortColumn)
        setDirection(direction)
        setPages(newPagedData.length)
        setData(newPagedData[0])

    }, [items])

    const sortTable = (column) => {
        let sortDirection = localDirection === 'ascending' ? 'descending' : 'ascending'
        let orderedData = _.sortBy(items, [column])

        if (sortDirection === 'descending') {
            orderedData = orderedData.slice().reverse()
        }

        let newPagedData = _.chunk(orderedData, 100)
        // setPages(newPagedData.length)
        setPagedData(newPagedData)
        setSortColumn(column)
        setDirection(sortDirection)
        setPages(newPagedData.length)
        setData(newPagedData[0])
    }



    if (!_.isEmpty(items)) {

        return (
            <div className={'scrollAuto'}>
                <Table sortable celled selectable padded={true} size={'small'} columns={header.length}>
                    <Table.Header fullWidth>
                        <Table.Row>
                            {
                                header.map((column) => (
                                    <Table.HeaderCell
                                        key={column}
                                        sorted={column === localSortColumn ? localDirection : null}
                                        onClick={() => sortTable(column)}
                                    >
                                        {_.capitalize(I18n.get(column))}
                                    </Table.HeaderCell>
                                ))
                            }
                            {
                                actions && (
                                    <Table.HeaderCell>{I18n.get('action')}</Table.HeaderCell>
                                )
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((item, index) => (
                            <TableItem
                                key={item.id}
                                {...item}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onSelect={onSelect}
                                tableHeader={header}
                                item={item}
                                deleteItemId={deleteItemId}
                                actions={actions}
                            />
                        ))}
                    </Table.Body>
                </Table>
                {
                    pagination && (
                        <Pagination
                            defaultActivePage={1}
                            onPageChange={handlePageClick}
                            size='mini'
                            siblingRange={1}
                            boundaryRange={3}
                            totalPages={pages}
                        />
                    )
                }
            </div>
        )
    } else {
        return (
            <Header as='h2' color={"red"}>
                <Icon name='battery empty'/>
                <Header.Content>
                    {I18n.get('no_data_header')}
                    <Header.Subheader>{I18n.get('no_data_content')}</Header.Subheader>
                </Header.Content>
            </Header>
        )
    }


}

CustomTable.propTypes = {
    items: PropTypes.array.isRequired,
    header: PropTypes.array.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
}

export default CustomTable;