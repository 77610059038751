import React, {useEffect, useState} from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Amplify, {Storage, Auth} from "aws-amplify";
import * as d3 from "d3";
import _ from 'lodash';
import Load from "./loader";

import awsconfig from '../aws-exports'
import moment from "moment";
import ItemTable from "./wordcloud-item-table";
import {Input} from "semantic-ui-react";

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

const SentimentGraph = ({eoi, pPrice}) => {
    const [baseData, setBaseData] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [sentimentThreshold, setSentimentThreshold] = useState(0.9)
    const [selectedItems, setSelectedItems] = useState([])

    let dataSourceBar = {
        chart: {
            caption: "Item Graph",
            multicanvas: false,
            labelDisplay: "wrap",
            theme: "fusion",
            showZeroPlane: "1",
            zeroPlaneAlpha: "50",
            zeroPlaneThickness: "3",
            decimals: "2",
            decimalSeparator: ".",
            thousandSeparator: ",",
            formatNumberScale: "1",
            syncAxisLimits: '1'
        },
        xAxis: {
            plot: "Item Date",
            binning: {
                year: [],
                month: [],
                day: [],
                hour: [parseInt(eoi.p_hours)],
                minute: [],
                second: [],
                millisecond: [],
            },
        },
        Series: 'Sentiment',
        yaxis: [
            {
                plot: [
                    {
                        value: "Items",
                        type: "column",
                        connectnulldata: true,
                        aggregation: "Sum"
                    }
                ],
                title: "Sentiment Count"
            }
        ]
    };

    let [barDs, setBarDs] = useState({
        type: "timeseries",
        renderAt: "container",
        width: "100%",
        height: "80%",
        dataSource: dataSourceBar,
        events: {
            drawComplete: function (eventObj, dataObj) {
                console.log("Draw completed:", eventObj);
                setIsFetching(false);
            },
            drawCancelled: function (ev) {
                console.log("Draw Cancelled:", ev);
                setIsFetching(false);
            }
        }
    })

    const barchartSchema = [
        {
            "name": "Item Date",
            "type": "date",
            "format": "%Y-%m-%d %H:%M:%S"
        },
        {
            "name": "Sentiment",
            "type": "string"
        },
        {
            "name": "Items",
            "type": "number"
        }
    ]

    const formatD3Data = (data) => {
        if (_.isEmpty(data)) return []

        let items = []

        _.forEach(data, function (item) {
            // Price data
            items.push([item.insert_date, item.label, 1])
        });

        return items
    }

    const show_items = (plot_data) => {
        if (_.isEmpty(baseData) || _.isUndefined(plot_data)) return

        // Calculate P end date based on selected period (1,2,4,8)
        let p_start_date = moment(plot_data.startText).format('YYYY-MM-DD HH:00:00')
        let p_end_date = moment(p_start_date).add(eoi.p_hours, 'hours').format('YYYY-MM-DD HH:00:00')

        // filter items in the datarange of selected plot
        let filtered_items = _.filter(baseData, obj => (
            obj.insert_date >= p_start_date && obj.insert_date < p_end_date &&
            obj.score >= sentimentThreshold)
        )

        setSelectedItems(filtered_items)
    }

    const parse_p_price = () => {

        let from_date = ''
        switch (pPrice) {
            case '1D':
                from_date = moment().add(-1, 'day').format('YYYY-MM-DD HH:00:00')
                break;
            case '7D':
                from_date = moment().add(-7, 'days').format('YYYY-MM-DD HH:00:00')
                break;
            case '1M':
                from_date = moment().add(-1, 'month').format('YYYY-MM-DD HH:00:00')
                break;
            case '3M':
                from_date = moment().add(-3, 'month').format('YYYY-MM-DD HH:00:00')
                break;
            default:
                from_date = moment().add(-1, 'day').format('YYYY-MM-DD HH:00:00')
        }

        return from_date

    }

    const filter_data = (data) => {
        if (_.isEmpty(data)) return

        let filtered_data = _.filter(data, obj => (obj.score >= sentimentThreshold && obj.insert_date >= parse_p_price()))

        // Format data for D3 library
        let formatted_data = formatD3Data(filtered_data)

        return formatted_data
    }

    useEffect(() => {
        if (isFetching || _.isEmpty(pPrice) || _.isEmpty(baseData)) return

        const barData = Object.assign({}, barDs);
        delete barData.dataSource.data

        let filteredData = filter_data(baseData)

        if (!_.isEmpty(filteredData)) {
            // Item fusion table
            const itemFusionTable = new FusionCharts.DataStore().createDataTable(
                filteredData,
                barchartSchema
            );

            // Time series data
            barData.dataSource.data = itemFusionTable;

            // Set Binning
            let binning = {
                year: [],
                month: [],
                day: [],
                hour: [eoi.p_hours],
                minute: [],
                second: [],
                millisecond: [],
            }

            barData.dataSource.xAxis.binning = binning;
            barData.events['dataPlotClick'] = function (eventObj, dataObj) {
                show_items(dataObj);
                console.log(dataObj);
            };
        }

        setBarDs(barData);

    }, [pPrice])

    useEffect(() => {
        if (!_.isNumber(parseFloat(sentimentThreshold)) || sentimentThreshold <= 0.00 || _.isEmpty(baseData)) return

        const barData = Object.assign({}, barDs);
        delete barData.dataSource.data

        let filteredData = filter_data(baseData)

        if (!_.isEmpty(filteredData)) {
            // Item fusion table
            const itemFusionTable = new FusionCharts.DataStore().createDataTable(
                filteredData,
                barchartSchema
            );

            // Time series data
            barData.dataSource.data = itemFusionTable;

            // Set Binning
            let binning = {
                year: [],
                month: [],
                day: [],
                hour: [eoi.p_hours],
                minute: [],
                second: [],
                millisecond: [],
            }

            barData.dataSource.xAxis.binning = binning;
            barData.events['dataPlotClick'] = function (eventObj, dataObj) {
                show_items(dataObj);
                console.log(dataObj);
            };
        }

        setBarDs(barData);

    }, [sentimentThreshold])

    useEffect(() => {
        if (_.isEmpty(baseData)) return

        const barData = Object.assign({}, barDs);
        delete barData.dataSource.data

        let filteredData = filter_data(baseData)

        if (!_.isEmpty(filteredData)) {
            // Item fusion table
            const itemFusionTable = new FusionCharts.DataStore().createDataTable(
                filteredData,
                barchartSchema
            );

            // Time series data
            barData.dataSource.data = itemFusionTable;

            // Set Binning
            let binning = {
                year: [],
                month: [],
                day: [],
                hour: [eoi.p_hours],
                minute: [],
                second: [],
                millisecond: [],
            }

            barData.dataSource.xAxis.binning = binning;
            barData.events['dataPlotClick'] = function (eventObj, dataObj) {
                show_items(dataObj);
                console.log(dataObj);
            };
        }

        setBarDs(barData);

    }, [baseData])

    useEffect(() => {
        if (_.isEmpty(eoi)) return

        try {

            // Get price data from each EOI
            const go = async () => {
                const _key = `${eoi.eoi}/item_data.json`
                const level = 'public'
                const link = await Storage.get(_key, {level})

                setIsFetching(true)

                await d3.json(link).then(function (data) {

                    // Set base data
                    setBaseData(data)

                    // Set EOI index data for D3
                    setIsFetching(false)
                });
            }
            go()

        } catch (error) {
            setIsFetching(false)
            console.log('querry failed ->', error)
        }

    }, [eoi])

    return (
        <div>
            {barDs.dataSource.data ? (
                <div>
                    <Input
                        label={{basic: true, content: 'Sentiment Threshold'}}
                        labelPosition='left'
                        floated={'left'}
                        type={'number'}
                        placeholder='Sentiment probability threshold...'
                        value={sentimentThreshold}
                        onChange={(e) => (setSentimentThreshold(e.target.value))}
                    />
                    <ReactFC {...barDs} />
                    <ItemTable items={selectedItems}/>
                </div>
            ) : (
                <div>
                    {(isFetching) && (
                        <Load text={"Loading item-sentiment data..."}/>
                    )}
                </div>
            )}
        </div>
    )
}

export default SentimentGraph