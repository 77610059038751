import React, {useEffect, useState} from "react";
import {Form, Message, FormInput, Popup, Icon, TextArea} from 'semantic-ui-react'
import _ from 'lodash';
import Amplify, {API, Auth} from "aws-amplify";
import {createContact} from "../graphql/mutations";

import awsconfig from '../aws-exports'
Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [inputs, setInputs] = useState({})
    const [disabled, setDisable] = useState(true)

    const authorFormFields = [
        {
            as: FormInput,
            label: 'Name',
            key: 'name',
            id: 'name',
            placeholder: 'Enter your name',
            required: true,
            type: 'string',
            error: false,
            hint: 'Enter your name'
        },
        {
            as: FormInput,
            label: 'Email',
            key: 'email',
            id: 'email',
            placeholder: 'Enter your email',
            required: true,
            type: 'string',
            error: false,
            hint: 'Enter your email'
        },
        {
            as: FormInput,
            label: 'Message',
            key: 'message',
            id: 'message',
            placeholder: 'Type your message here',
            required: true,
            type: 'textarea',
            error: false,
            hint: 'Enter your message'
        }
    ];

    const onSend = (e, contact) => {
        e.preventDefault()

        if (loading) return

        setLoading(true)

        const go = async (contact) => {

            try {
                const result = await API.graphql({
                    query: createContact,
                    variables: {
                        input: {
                            "name": inputs.name,
                            "email": inputs.email,
                            "message": inputs.message
                        },
                    },
                    authMode: "AWS_IAM"
                })

                if (!_.isEmpty(result.data.createContact)) {
                    let message = {
                        header: 'Success',
                        content: "Your message was received. We'll be contacting you soon!"
                    }
                    setMessage(message)
                    setSuccess(true)

                } else {
                    let message = {
                        header: 'Warning',
                        content: "We might have an error getting your message. Please try later."
                    }
                    setMessage(message)
                    setError(true)
                }
                setLoading(false)


            } catch (error) {
                console.log('querry failed ->', error)
                message.header = 'Error'
                message.content = error
                setMessage(message)
                setError(true)
                setLoading(true)
            }
        }
        go(contact)
    }

    const onChange = (e, d) => {
        e.preventDefault()

        if (!inputs) return

        const {id, value} = e.target;

        let form_inputs = inputs

        form_inputs = {
            ...form_inputs,
            [id]: value
        }
        setInputs(form_inputs);

    }

    const getInvalidFields = React.useCallback((inputs) => {

        let invalidsFields = [];

        _.map(authorFormFields, function (field) {
            if (field.required && !inputs[field.id]) {
                field.error = true;
                invalidsFields.push(`${field.label} is required`);
            } else {
                field.error = false;
            }
        })

        return invalidsFields;
    }, [])

    // fetch Word Cloud data
    useEffect(() => {
        if (!inputs) return

        let cleanForm = _.isEmpty(inputs);
        let invalids = cleanForm || getInvalidFields(inputs);

        if (invalids && !_.isEmpty(invalids)) {
            let message = {
                header: 'Validation error',
                list: invalids
            }

            setError(true)
            setDisable(true)
            setMessage(message)
        } else {
            setError(false)
            setDisable(cleanForm ? true : false)
        }

    }, [inputs, getInvalidFields])

    return (
        <div>
            <Form
                onSubmit={(e) => onSend(e)}
                loading={loading}
                error={error}
                success={success}
            >
                {(error || success) && (
                    <Message
                        success={success}
                        error={error}
                        header={message.header}
                        content={message.content}
                        list={message.list}
                    />
                )}
                {authorFormFields.map(field => {
                    if (field.type === 'textarea') {
                        return (
                            <Form.TextArea
                                {...field}
                                label={
                                    <label>{field.label}
                                        {field.hint && !_.isUndefined(field.hint) && (<Popup
                                            trigger={<Icon name='question circle' color='blue'
                                                           size='small'/>}
                                            content={field.hint}
                                            position='top right'
                                            hideOnScroll
                                        />)}
                                    </label>
                                }
                                value={_.isUndefined(inputs) || _.isEmpty(inputs[field.key]) ? '' : inputs[field.key]}
                                onChange={(e, d) => onChange(e, d)}
                            />
                        )
                    }

                    return (
                        <Form.Input
                            {...field}
                            label={
                                <label>{field.label}
                                    {field.hint && !_.isUndefined(field.hint) && (<Popup
                                        trigger={<Icon name='question circle' color='blue'
                                                       size='small'/>}
                                        content={field.hint}
                                        position='top right'
                                        hideOnScroll
                                    />)}
                                </label>
                            }
                            value={_.isUndefined(inputs) || _.isEmpty(inputs[field.key]) ? '' : inputs[field.key]}
                            onChange={(e, d) => onChange(e, d)}
                        />
                    )
                })}
                <Form.Button
                    basic fluid
                    type={'submit'}
                    color={'green'}
                    content={'Send'}
                    disabled={disabled}
                />
            </Form>
        </div>
    )
}

export default ContactForm;